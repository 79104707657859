
import { useTranslation } from "react-i18next";
const Intial = () => {
    const { t } = useTranslation()
    return (
        <div className='max-w-[400px] px-4 mx-auto' >
            <h1 className='font-medium text-[17px] text-[#fff] leading-[26px]'>
                {t(`l5-0-desc1`)}
            </h1>
            <h1 className='font-medium text-[17px] text-[#fff] mt-2 leading-[26px]'>
                {t(`l5-0-desc2`)}
            </h1>

        </div>
    )
}

export default Intial
