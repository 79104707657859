import { CSVLink } from 'react-csv';
const Table = ({ headers, data, getAllCsv, page }) => {
    console.log("data in number", page)
    return (
        <div className="overflow-x-auto mt-8">
            <div className="flex justify-between">
                <h2 className='text-white my-4' >All Users</h2>
                <a
                    href='https://simurg-backend.synceev.com/api/v1/user/download'
                    target='_blank'
                    className="button_secodry flex justify-center items-center"
                    getAllCsv
                    onClick={getAllCsv}
                >
                    Export CSV
                </a>
            </div>
            <table className="min-w-full border border-white bg-transparent">
                <thead className="bg-transparent">
                    <tr>
                        <th

                            className="px-4 py-2 border-b border-gray-300 text-left text-white text-sm font-medium"
                        >
                            ID
                        </th>
                        {headers.map((header) => (
                            <th
                                key={header.key}
                                className="px-4 py-2 border-b border-gray-300 text-left text-white text-sm font-medium"
                            >
                                {header.label}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 ? (
                        data.map((row, rowIndex) => (
                            <tr
                                key={rowIndex}
                                className={rowIndex % 2 === 0 ? 'bg-transparent' : 'bg-transparent'}
                            >
                                <td
                                    className="px-4 py-2 border-b border-white text-sm text-white"
                                >
                                    {((page - 1) * 10) + (rowIndex + 1)}
                                </td>
                                {headers.map((header) => (
                                    <td
                                        key={header.key}
                                        className="px-4 py-2 border-b border-white text-sm text-white"
                                    >
                                        {row[header.key]}
                                    </td>
                                ))}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td
                                colSpan={headers.length}
                                className="px-4 py-2 text-center text-gray-500"
                            >
                                No data available
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
