import React from 'react';
import Counting from '../../../assets/counting.svg';
import ScalarValue2 from '../../../assets/scalarValue2.svg';
import ScalarValue from '../../../assets/scalarValue.svg';
import SclorOneTr from '../../../assets/scale_one_tr.png'
import SclorTwoTr from '../../../assets/scale_two_tr.png'
import SclorOneHr from '../../../assets/scale_one_bks.png'
import SclorTwoHr from '../../../assets/scale_two_bks.png'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
export const sliderConfig = [
  {
    name: 'stressRating',
    labelTitleKey: 'stressRatingTitle',
    labelDescriptionKey: 'stressRatingDescription',
    minValue: 0,
    maxValue: 10,
    scalarTypes: ScalarValue2,
  },
  {
    name: 'stressTolerance',
    labelTitleKey: 'stressToleranceTitle',
    labelDescriptionKey: 'stressToleranceDescription',
    minValue: 0,
    maxValue: 10,
    scalarTypes: ScalarValue,
  },
];

const ScalarForm = ({ formik }) => {
  const { t } = useTranslation()
  const currentLanguage = useSelector((state) => state.language);
  const sliderConfigInner = [
    {
      name: 'stressRating',
      labelTitleKey: 'stressRatingTitle',
      labelDescriptionKey: 'stressRatingDescription',
      minValue: 0,
      maxValue: 10,
      scalarTypes: currentLanguage?.language == 'de' ? ScalarValue2 : currentLanguage?.language == 'tr' ? SclorOneTr : SclorOneHr,
    },
    {
      name: 'stressTolerance',
      labelTitleKey: 'stressToleranceTitle',
      labelDescriptionKey: 'stressToleranceDescription',
      minValue: 0,
      maxValue: 10,
      scalarTypes: currentLanguage?.language == 'de' ? ScalarValue : currentLanguage?.language == 'tr' ? SclorTwoTr : SclorTwoHr,
    },
  ];
  return (
    <div className="flex flex-col gap-5 items-start max-w-[370px] mx-auto -mt-6">
      <p className="text-[16px] font-[500] text-white text-center">
        {t(`l1-1-desc1`)}
      </p>

      {sliderConfigInner.map(slider => (
        <div key={slider.name} className="flex flex-col gap-3 items-start w-full">
          <div>
            <p className="text-[16px] font-bold   text-white text-center">
              {t(`sliderConfig.${slider.labelTitleKey}`)}
            </p>
            <p className="text-[16px] font-medium text-white text-center">
              {t(`sliderConfig.${slider.labelDescriptionKey}`)}
            </p>
          </div>

          <div className="relative w-full">
            <div className="absolute top-0 left-5 w-full h-8">
              <img src={Counting} alt="" />
            </div>
            <input
              type="range"
              min={slider.minValue}
              max={slider.maxValue}
              step={1}
              value={formik.values[slider.name]}
              onChange={e => formik.setFieldValue(slider.name, Number(e.target.value))}
              className="absolute w-[90%] mx-auto h-1 top-[23px] left-[50%] translate-x-[-50%] bg-gray-300 focus:outline-none custom-range-slider"
            />
            <div className="mt-4">
              <img src={slider.scalarTypes} alt="" /> </div>
          </div>

          {formik.touched[slider.name] && formik.errors[slider.name] && (
            <p className="text-sm text-red-500 text-left">
              {formik.errors[slider.name]}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default ScalarForm;
