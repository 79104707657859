import * as Yup from 'yup';
import { sliderConfig } from '../../common/forms/ScalarForm';
import { t } from 'i18next';

export const questions = [
  {
    keyName: 'comfortable',
    options: [
      { label: 'always', value: 'immer' },
      { label: 'sometimes', value: 'manchmal' },
      { label: 'never', value: 'nie' },
    ],
  },
  {
    keyName: 'relaxed',
    options: [
      { label: 'always', value: 'immer' },
      { label: 'sometimes', value: 'manchmal' },
      { label: 'never', value: 'nie' },
    ],
  },
  {
    keyName: 'sleepEnough',
    options: [
      { label: 'always', value: 'immer' },
      { label: 'sometimes', value: 'manchmal' },
      { label: 'never', value: 'nie' },
    ],
  },
  {
    keyName: 'enoughToDrinkAndEat',
    options: [
      { label: 'always', value: 'immer' },
      { label: 'sometimes', value: 'manchmal' },
      { label: 'never', value: 'nie' },
    ],
  },
  {
    keyName: 'concentrateWell',
    options: [
      { label: 'always', value: 'immer' },
      { label: 'sometimes', value: 'manchmal' },
      { label: 'never', value: 'nie' },
    ],
  },
  {
    keyName: 'learnEnough',
    options: [
      { label: 'always', value: 'immer' },
      { label: 'sometimes', value: 'manchmal' },
      { label: 'never', value: 'nie' },
    ],
  },
  {
    keyName: 'tookBreak',
    options: [
      { label: 'always', value: 'immer' },
      { label: 'sometimes', value: 'manchmal' },
      { label: 'never', value: 'nie' },
    ],
  },
  {
    keyName: 'calmAndComposed',
    options: [
      { label: 'always', value: 'immer' },
      { label: 'sometimes', value: 'manchmal' },
      { label: 'never', value: 'nie' },
    ],
  },
  {
    keyName: 'enjoyedHobbies',
    options: [
      { label: 'always', value: 'immer' },
      { label: 'sometimes', value: 'manchmal' },
      { label: 'never', value: 'nie' },
    ],
  },
  {
    keyName: 'sensedOverload',
    options: [
      { label: 'always', value: 'immer' },
      { label: 'sometimes', value: 'manchmal' },
      { label: 'never', value: 'nie' },
    ],
  },
  {
    keyName: 'didntBrood',
    options: [
      { label: 'always', value: 'immer' },
      { label: 'sometimes', value: 'manchmal' },
      { label: 'never', value: 'nie' },
    ],
  },
  {
    keyName: 'enjoyedLife',
    options: [
      { label: 'always', value: 'immer' },
      { label: 'sometimes', value: 'manchmal' },
      { label: 'never', value: 'nie' },
    ],
  },
  {
    keyName: 'enjoyedSocializing',
    options: [
      { label: 'always', value: 'immer' },
      { label: 'sometimes', value: 'manchmal' },
      { label: 'never', value: 'nie' },
    ],
  },
  {
    keyName: 'confidentAboutProject',
    options: [
      { label: 'always', value: 'immer' },
      { label: 'sometimes', value: 'manchmal' },
      { label: 'never', value: 'nie' },
    ],
  },
];

// export const inputQuestion = [
//   {
//     question:
//       'Welche positiven Verhaltensweisen zeichnen Dich aus (z.B. Sport, gesunde Ernährung etc.)?',
//     keyName: 'positiveBehaviors',
//   },
//   {
//     question:
//       'Welche schlechten Gewohnheiten hast Du (z.B. Rauchen, Schlafmangel etc.)?',
//     keyName: 'badHabits',
//   },
//   {
//     question: 'Was sind Deine Stärken?',
//     keyName: 'strengths',
//   },
//   {
//     question: 'Was sind Deine Schwächen?',
//     keyName: 'weaknesses',
//   },
//   {
//     question: 'Was sind Deine Hobbys?',
//     keyName: 'hobbies',
//   },

//   {
//     question: 'Worauf bist Du besonders stolz?',
//     keyName: 'enjoyBeingWith',
//   },
//   {
//     question: `Mit welchen Menschen bist Du gerne
//     zusammen?`,
//     keyName: 'proudOf',
//   },
//   {
//     question: `Was gibt Dir Kraft?`,
//     keyName: 'strength',
//   },
//   {
//     question: `Was macht Dich glücklich?`,
//     keyName: 'happiness',
//   },
//   {
//     question: `Was möchtest Du in 5 Jahren erreicht
//     haben?`,
//     keyName: 'futureGoals',
//   },
// ];

export const inputQuestion = [
  {
    keyName: 'positiveBehaviors',
  },
  {
    keyName: 'badHabits',
  },
  {
    keyName: 'strengths',
  },
  {
    keyName: 'weaknesses',
  },
  {
    keyName: 'hobbies',
  },
  {
    keyName: 'proudOf',
  },
  {
    keyName: 'enjoyBeingWith',
  },
  {
    keyName: 'strength',
  },
  {
    keyName: 'happiness',
  },
  {
    keyName: 'futureGoals',
  },
];

// export const levelOneMcqs = [
//   {
//     question: 'Familie',
//     option: [{ value: 'Ja', label: 'Ja' }],
//   },
//   {
//     question: 'Sportliche Aktivitäten',
//     option: [{ value: 'Ja', label: 'Ja' }],
//   },
//   {
//     question: 'Freunde',
//     option: [{ value: 'Ja', label: 'Ja' }],
//   },
//   {
//     question: 'Haustiere',
//     option: [{ value: 'Ja', label: 'Ja' }],
//   },
//   {
//     question: 'Religion',
//     option: [{ value: 'Ja', label: 'Ja' }],
//   },
//   {
//     question: 'Anderes',
//     option: [{ value: 'Ja', label: 'Ja' }],
//   },
// ];

export const levelOneMcqs = [
  {
    keyName: 'family',
    option: [{ value: 'Ja', label: 'Ja' }],
  },
  {
    keyName: 'sportsActivities',
    option: [{ value: 'Ja', label: 'Ja' }],
  },
  {
    keyName: 'friends',
    option: [{ value: 'Ja', label: 'Ja' }],
  },
  {
    keyName: 'pets',
    option: [{ value: 'Ja', label: 'Ja' }],
  },
  {
    keyName: 'religion',
    option: [{ value: 'Ja', label: 'Ja' }],
  },
  {
    keyName: 'other',
    option: [{ value: 'Ja', label: 'Ja' }],
  },
];

export const levelOneMcqs_options = [{ label: 'Ja' }];

export const DATA_Playlist = [
  {
    titleKey: 'uploadPlaylistTitle',
    data: [
      {
        questionKey: 'sadSongQuestion',
        keyName: 'sadSong',
      },
      {
        questionKey: 'upsetSongQuestion',
        keyName: 'upsetSong',
      },
      {
        questionKey: 'anxiousSongQuestion',
        keyName: 'anxiousSong',
      },
      {
        questionKey: 'happySongQuestion',
        keyName: 'happySong',
      },
    ],
  },
];

export const resources_options = [
  { label: 'veryGood', value: 'SehrGut', keyName: 'veryGood' },
  { label: 'good', value: 'gut', keyName: 'good' },
  { label: 'bad', value: 'schlecht', keyName: 'bad' },
  { label: 'veryBad', value: 'SehrSchlecht', keyName: 'veryBad' },
];

export const List = [
  {
    title: `Denke an eine Stresssituation oder an ein Problem der Vergangenheit! Nun beantworte `,
    url: 'drive_link_12',
    link: 'diese Fragen',
    keyName: 'pastQuestion'
  },
  {
    title: `Nun kommen wir wieder zurück in die Gegenwart! Nun beantworte  `,
    url: 'drive_link_13',
    link: 'diese Fragen',
    keyName: 'presentQuestion'
  },
  {
    title: `Nun werfe einen Blick in die Zukunft! Nun beantworte  `,
    url: 'drive_link_14',
    link: 'diese Fragen',
    keyName: 'futureQuestion'
  },
];


export const resources_list = [
  {
    question: 'Familie',
    keyName: 'family',
  },
  {
    question: 'Verwandte',
    keyName: 'related',
  },
  {
    question: 'Freund:innen ',
    keyName: 'friends',
  },
  {
    question: 'Partner:innen ',
    keyName: 'partners',
  },
  {
    question: 'Lehrer:innen',
    keyName: 'teachers',
  },
  {
    question: 'Mitschüler:innen',
    keyName: 'classmates',
  },
  {
    question: 'Soziale Medien',
    keyName: 'socialMedia',
  },

  {
    question: 'Schule',
    keyName: 'school',
  },
  {
    question: 'Lehrstelle/Arbeit',
    keyName: 'apprenticeshipWork',
  },
  {
    question: 'Gesundheit',
    keyName: 'health',
  },
  {
    question: 'Attraktivität/Aussehen',
    keyName: 'attractiveness',
  },
  {
    question: 'Charakter',
    keyName: 'character',
  },
  {
    question: 'Intelligenz',
    keyName: 'intelligence',
  },
  {
    question: 'Sprache',
    keyName: 'language',
  },
  {
    question: 'Sport',
    keyName: 'sport',
  },
  {
    question: 'Hobbies',
    keyName: 'hobbies',
  },

  {
    question: 'Ernährung',
    keyName: 'nutrition',
  },
  {
    question: 'Schlaf',
    keyName: 'sleep',
  },
  {
    question: 'Zukunftspläne',
    keyName: 'futurePlans',
  },
  {
    question: 'Positives Denken',
    keyName: 'positiveThinking',
  },
  {
    question: 'Selbstvertrauen',
    keyName: 'confidence',
  },
  {
    question: 'Selbstbestimmung',
    keyName: 'selfDetermination',
  },
  {
    question: 'Religion/Spiritualität',
    keyName: 'religionSpirituality',
  },
  {
    question: 'Finanzen (Geld)',
    keyName: 'finance',
  },
  {
    question: 'Andere',
    keyName: 'other',
  },
];


export const stressOptions = [
  {
    questionKey: 'photosOfFriendsRelatives',
    value: 'PhotosOfFriendsRelatives',
  },
  {
    questionKey: 'photosFromVacationsParties',
    value: 'PhotosFromVacationsParties',
  },
  {
    questionKey: 'photosOfPets',
    value: 'PhotosOfPets',
  },
  {
    questionKey: 'prayersQuotes',
    value: 'PrayersQuotes',
  },
  {
    questionKey: 'favoriteCDVideo',
    value: 'FavoriteCDVideo',
  },
  {
    questionKey: 'favoriteBookNovel',
    value: 'FavoriteBookNovel',
  },
  {
    questionKey: 'selfPaintedPictures',
    value: 'SelfPaintedPictures',
  },
  {
    questionKey: 'copiesOfGoodReferences',
    value: 'CopiesOfGoodReferences',
  },
  {
    questionKey: 'trophiesAwards',
    value: 'TrophiesAwards',
  },
  {
    questionKey: 'other',
    value: 'Other',
  },
];


export const ListDrive = [
  {
    titleKey: 'doSomethingNiceForFriendsFamily',
    url: 'drive_link_6',
  },
  {
    titleKey: 'motivateYourselfToBeActive',
    url: 'drive_link_7',
  },
  {
    titleKey: 'tryToChangeYourFeelings',
    url: 'drive_link_8',
  },
  {
    titleKey: 'tryToReplaceYourThoughts',
    url: 'drive_link_9',
  },
  {
    titleKey: 'tryToLeaveTheDifficultSituation',
    url: 'drive_link_10',
  },
];

export const questionsList = [
  {
    questionKey: 'isFighter',
    value: 'isFighter',
  },
  {
    questionKey: 'canLearnToCope',
    value: 'canLearnToCope',
  },
  {
    questionKey: 'canFindSolutions',
    value: 'canFindSolutions',
  },
  {
    questionKey: 'everythingHasASolution',
    value: 'everythingHasASolution',
  },
  {
    questionKey: 'upsAndDownsArePartOfLife',
    value: 'upsAndDownsArePartOfLife',
  },
  {
    questionKey: 'stressfulSituationsPass',
    value: 'stressfulSituationsPass',
  },
  {
    questionKey: 'nothingHasHelpedMe',
    value: 'nothingHasHelpedMe',
  },
  {
    questionKey: 'other',
    value: 'Other',
  },
];






export const firstSevenResources = resources_list.slice(0, 7);
export const secondSevenResources = resources_list.slice(7, 16);
export const thirdSevenResources = resources_list.slice(16);


export const firstSevenQuestions = questions.slice(0, 7);
export const lastSevenQuestions = Array.isArray(questions) ? questions.slice(7) : [];


export const firstFiveInputQuestions = inputQuestion.slice(0, 5)
export const lastFiveInputQuestions = inputQuestion.slice(5)


export const getValidationSchema = (renderForm, t) => {
  // console.log('renderForm=========>>>>>>>>', {renderForm});
  if (renderForm === 0) {
    return Yup.object(
      sliderConfig.reduce((schema, slider) => {
        schema[slider.name] = Yup.number()
          .required('This field is required')
          .min(slider.minValue, `Minimum value is ${slider.minValue}`)
          .max(slider.maxValue, `Maximum value is ${slider.maxValue}`);
        return schema;
      }, {}),
    );
  } else if (renderForm === 1) {
    return Yup.object(
      firstSevenQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          t(`levelOneError.error_three`),
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 2) {
    return Yup.object(
      lastSevenQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          t(`levelOneError.error_three`),
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 3) {
    return Yup.object(
      firstFiveInputQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          t(`levelOneError.error_one`),
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 4) {
    return Yup.object(
      lastFiveInputQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          t(`levelOneError.error_one`),
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 5) {
    return Yup.object().shape({
      answers: Yup.array().of(
        Yup.object().shape({
          checked: Yup.boolean(),
          input: Yup.string().test(
            'is-checked-input-required',
            'Bitte fülle dieses Feld aus.', // This is what should be returned if invalid
            function (value) {
              const { checked } = this.parent;
              // console.log('Checked:', checked, 'Value:', value); // Debugging output
              return !checked || (value && value.trim() !== '');
            },
          ),
        }),
      ),
    });
  } else if (renderForm === 6) {
    return Yup.object(
      DATA_Playlist[0].data.reduce((schema, item) => {
        schema[item.keyName] = Yup.string().required(
          t(`levelOneError.error_one`),
        );
        return schema;
      }, {}),
    );
  }

  // Add other cases as needed
  return Yup.object(); // Default empty schema
};

export const getInitialValues = (initialValues, userInfo) => {
  if (Object.keys(userInfo).length === 0) {
    // If userInfo is empty, return initialValues as-is
    return initialValues;
  }

  // Merge userInfo values into initialValues
  return {
    ...initialValues,
    ...Object.keys(initialValues).reduce((updatedValues, key) => {
      updatedValues[key] = userInfo[key] ?? initialValues[key];
      return updatedValues;
    }, {}),
  };
};
