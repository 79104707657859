import React from 'react'
import mainBG from '../../../assets/EinführungMainBG.png'
import ellipseBG from '../../../assets/EllipseBG.png'
import ellipseTop from '../../../assets/EllipseTop.png'
import avatar1 from "../../../assets/avatar1.png"
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { avatars } from '../../../constants/data'
import { useTranslation } from "react-i18next";
const FinalScreen = ({ setRenderForm }) => {
    const navigate = useNavigate();
    const { t } = useTranslation()
    const { userInfo, loading } = useSelector(state => state.initialUserInfo);

    const avatarImg = userInfo && userInfo?.selectedAvatar ? avatars[userInfo?.selectedAvatar - 1] : avatars[0]
    console.log(`data in userInfo`, userInfo, avatarImg)
    return (
        <>
            <div style={{ background: `url(${mainBG})` }} className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'>
                <div style={{ background: `url(${ellipseTop})` }} className='min-h-[30vh] !bg-cover !bg-no-repeat !bg-bottom relative flex justify-center items-center'>
                    <h1 className='font-normal text-2xl text-center text-white noto-sans'> {t(`s-16-title`)} {userInfo && userInfo?.nickname} </h1>
                </div>
                <div className='w-full absolute top-[35%]'>

                    <img src={avatarImg?.src} className='mx-auto w-28' />
                </div>

                <div style={{ background: `url(${ellipseBG})` }} className='min-h-[40vh] !bg-cover !bg-no-repeat !bg-top relative'>
                    <div className='relative pt-16 w-full max-w-[25%] flex flex-col justify-center mx-auto h-full justify-self-center text-center pb-4'>
                        <button className='font-bold text-2xl text-[#FF7347D1] bg-[#F9DCD4] rounded-[10px] p-2 text-center leading-8 w-[250px] mx-auto' onClick={() => setRenderForm(16)} >{t(`s-16-btn`)}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FinalScreen