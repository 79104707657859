import React, { useEffect, useRef, useState } from 'react';
import ellipseBG from '../../assets/EllipseBG.png'
import { useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getValidationSchema } from './constant';
import { useSelector, useDispatch } from 'react-redux';
import ScalarForm, { sliderConfig } from '../common/forms/ScalarForm';
import LevelRadioSelectionlastSeven from '../common/forms/LevelRadioSelectionlastSeven';
import { firstSevenQuestions, getInitialValues, lastSevenQuestions, ListDrive } from '../common/constant/data';
import LevelRadioSelection from '../common/forms/LevelRadioSelection';
import Intial from './forms/initial';
import techVideo from '../../assets/tech.mov'
import { LevelTwoRed, StrengthData } from '../../constants/data';
import { setUserLevelTwoInfo } from '../../redux/Started'
import StressForm from '../common/forms/StressForm';
import StrengthForm from '../common/forms/StrengthForm';
import { handleAddedPersonalInfoAction } from '../../redux/Started/apisActions';
import Spiner from '../common/constant/spiner';
import IchForm from './forms/ichForm';
import { useTranslation } from "react-i18next";
import OverviewMail from '../common/forms/overviewMail';
import ModuleNav from '../common/forms/moduleNav';

const LevelTwo = () => {
    const isSmallScreen = window.innerHeight <= 600;
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const extractInitialValue = () => {
        const renderValue = searchParams.get("render");
        return renderValue ? parseInt(renderValue, 10) : 0;
    };

    const [renderForm, setRenderForm] = useState(extractInitialValue);
    const dispatch = useDispatch()
    const [validationSchema, setValidationSchema] = useState(
        getValidationSchema(renderForm, t),
    );

    useEffect(() => {
        setValidationSchema(getValidationSchema(renderForm - 1, t)); // Update schema when renderForm changes
    }, [renderForm]);

    const { levelTwoForm, loading } = useSelector(state => state.initialUserInfo);
    const { userID } = useSelector(state => state.user);
    const levelTwoInitialValues = {
        ...sliderConfig.reduce(
            (values, slider) => ({
                ...values,
                [slider.name]: 0,
            }),
            {},
        ),
        ...firstSevenQuestions.reduce(
            (values, question) => ({
                ...values,
                [question.keyName]: '', // Default value for each question
            }),
            {},
        ),
        ...lastSevenQuestions.reduce(
            (values, question) => ({
                ...values,
                [question.keyName]: '', // Default value for each question
            }),
            {},
        ),
        sociogram: ["", "", "", "", "", "", ""],
        sentences: [],
        sourcesOfStrength: [], // Array of selected options
        otherSourcesOfStrength: '',
    }
    const formik = useFormik({
        initialValues: getInitialValues(levelTwoInitialValues, levelTwoForm),
        validationSchema,
        onSubmit: values => {
            const data = {
                userId: userID || '',
                LevelTwo: JSON.stringify(values),
            };
            if (renderForm === 8) {
                dispatch(setUserLevelTwoInfo(values));
                dispatch(
                    handleAddedPersonalInfoAction({
                        data,
                        // callback: res => navigate('/level-three'),
                        callback: res => setRenderForm(renderForm + 1),
                    }),
                );

            } else {
                if (renderForm == 11) {
                    navigate('/level-three')
                }
                formik.setTouched({});
                setRenderForm(renderForm + 1);

            }

        },
    });

    return (
        <>
            <div className='self-center pt-5 text-center '>
                {renderForm === 2 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >
                            {t(`l1-2-title`)}
                        </h1>
                        <h1 className='text-[14px] text-mainBlue font-bold italic ' >
                            {t(`l1-2-title-one`)}

                        </h1>
                        <h1 className='text-[14px] text-mainBlue font-semibold' >    {t(`l1-2-title-two`)}</h1>
                    </>
                )}
                {renderForm === 0 && (<>
                    <h1 className='text-[16px] text-mainBlue font-bold italic' >Level 2</h1>
                </>)}
                {renderForm === 5 && (<>
                    <h1 className='text-[16px] text-mainBlue font-bold italic' >{t(`l2-5--title1`)}</h1>
                </>)}
                {renderForm === 6 && (<>
                    <h1 className='text-[16px] text-mainBlue font-bold italic' >{t(`l2-6-title`)}</h1>
                </>)}
                {renderForm === 4 && (<>
                    <h1 className='text-[16px] text-mainBlue font-bold italic' >{t(`l2-4--title1`)}</h1>
                </>)}
                {renderForm === 7 && (<>
                    <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l2-7-title`)} </h1>
                </>)}
                {renderForm === 8 && (<>
                    <h1 className='text-[16px] text-mainBlue font-bold italic' >{t(`l2-8-title`)}</h1>
                </>)}
                {renderForm === 9 && (<>
                    <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`overview_title_super`)} <br /> {t(`introduction_l2_title`)}</h1>
                </>)}
                {renderForm === 10 && (<>
                    <h1 className='text-[16px] text-mainBlue font-bold italic' >{t(`overview_title`)}</h1>
                </>)}
                {renderForm === 11 && (<>
                    <h1 className='text-[16px] text-mainBlue font-bold italic' >{t(`overview_title`)} <br /> Level 2 </h1>
                </>)}
                {
                    renderForm === 0 && (
                        <>
                            <h1 className='text-[16px] text-mainBlue font-bold italic ' >
                                {t(`l2-0-title`)} </h1>
                            <h1 className='text-[16px] text-mainBlue font-semibold' >  {t(`l2-0-title_two`)}</h1>
                        </>
                    )
                }

            </div>
            <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                <div className={`relative pt-20 w-full max-w-[36rem] flex flex-col mx-auto justify-between h-full justify-self-center text-center py-2`}>
                    {renderForm === 0 && <Intial />}
                    {renderForm === 1 && <ScalarForm formik={formik} />}
                    <div className="max-w-[35rem] mx-auto">

                        {renderForm === 2 && <LevelRadioSelection formik={formik} />}
                        {renderForm === 3 && <LevelRadioSelectionlastSeven formik={formik} />}
                    </div>
                    {renderForm === 4 && (
                        <div className="flex flex-col gap-5 justify-start items-center">
                            <p className={`text-xl font-bold text-white`}>
                                {t(`l2-4-heading`)}
                            </p>
                            {ListDrive?.map((item, index) => {
                                return (
                                    <a href={t(`${item?.url}`)} target='_blank' className={` font-bold text-white italic underline mb-1  text-center`}> {t(`list_drive.${item.titleKey}`)}</a>
                                )
                            })}


                        </div>
                    )}

                    {renderForm === 5 && (
                        <IchForm formik={formik} isSmallScreen={isSmallScreen} />
                    )}

                    {renderForm === 6 && (
                        <div className="flex flex-col gap-1 justify-start items-center">
                            <StressForm formik={formik} />
                        </div>
                    )}

                    {renderForm === 7 && (
                        <div className="flex flex-col gap-0 justify-start items-center">
                            <p className={`${isSmallScreen ? 'text-xs' : 'text-sm'} font-bold italic text-white`}> {t(`l2-7-desc`)}</p>
                            <p className={`${isSmallScreen ? 'text-xs' : 'text-sm'} font-bold italic text-white ${isSmallScreen ? 'mt-3' : 'mt-5'}`}>{t(`l2-7-heading`)}</p>
                            <div className='mt-4'>
                                <video
                                    className={`w-full ${isSmallScreen ? 'h-[100px]' : 'h-auto'} max-w-[300px] mx-auto`}

                                    controls
                                >
                                    <source src={t(`video_link_4`)} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    )}

                    {renderForm === 8 && (
                        <StrengthForm formik={formik} />
                    )}
                    {renderForm === 9 && <OverviewMail />}
                    {renderForm === 10 && <ModuleNav />}
                    {renderForm === 11 && <div className='text-left' >

                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`}  >
                            {t(`overview_module_start_two`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_1_two`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_2_two`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_3_two`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_4_two`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_5_two`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_6_two`)}
                        </p>
                    </div>}


                    <div className={`${isSmallScreen ? 'pt-2' : 'pt-4'} flex gap-2`}>
                        <button className='w-full button_primary' onClick={() => {
                            if (renderForm === 0) {
                                navigate('/level-one?render=12');
                            } else {
                                setRenderForm(renderForm - 1);
                            }
                            // navigation.goBack();
                        }} >{t(`s-2-btn1`)}</button>
                        <button className='w-full button_secodry relative' onClick={() => {
                            formik.handleSubmit();
                        }}
                            disabled={loading}
                        >

                            {loading && (
                                <Spiner />
                            )}
                            <span className={loading ? "opacity-0" : ""}>{t(`s-2-btn2`)}</span>

                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LevelTwo
