import React from "react";
import { DATA_Playlist } from "../../common/constant/data";
import { useTranslation } from "react-i18next";
const PlayList = ({ formik }) => {
    const { t } = useTranslation()
    return (
        <div className="flex flex-col gap-2 ">
            <h2 className="text-2xl font-bold max-w-[400px] mx-auto italic text-white">
                {t(`l1-8-title`)}
            </h2>

            {DATA_Playlist?.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                    <h3 className="text-[14px] font-medium text-white mb-4">{t(`playlist.${section.titleKey}`)}</h3>
                    {section.data.map((item, itemIndex) => (
                        <div
                            key={`${item.keyName}-${itemIndex}`}
                            className="flex justify-between items-center mb-4"
                        >
                            <label
                                htmlFor={`input-${item.keyName}`}
                                className="text-base font-regular text-white"
                            >
                                {t(`playlist.${item.questionKey}`)}
                            </label>
                            <input
                                id={`input-${item.keyName}`}
                                type="text"
                                className={`w-1/5 px-3 py-2 rounded-md !text-black border ${formik.errors[item.keyName]
                                    ? "border-red-500"
                                    : "border-gray-300"
                                    } focus:outline-none focus:ring focus:ring-indigo-500`}
                                value={formik.values[item.keyName] || ""}
                                onChange={(e) =>
                                    formik.setFieldValue(item.keyName, e.target.value)
                                }
                                onBlur={() => formik.setFieldTouched(item.keyName, true)}
                            />
                        </div>
                    ))}
                </div>
            ))}

            {Object.keys(formik.errors).length > 0 && (
                <p className="text-sm text-red-500 mt-2">
                    Bitte fülle alle Felder aus, um fortzufahren.
                </p>
            )}
        </div>
    );
};

export default PlayList;
