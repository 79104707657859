import React from 'react';
import { firstFiveInputQuestions, inputQuestion } from '../constant/data';
import { useTranslation } from 'react-i18next';
//
// export const firstFiveInputQuestions = inputQuestion.slice(0, 5);

const ProfileFormOne = ({ formik }) => {
  const isSmallScreen = window.innerHeight <= 600;
  const { t } = useTranslation()
  return (
    <div>
      <h1 className={`text-center text-white max-w-[30rem] mx-auto font-semibold ${isSmallScreen ? 'text-xs' : 'text-sm'}`}>
        {t(`l1-5-title`)}
      </h1>
      <div>
        <div className='flex flex-col items-start mb-2'>
          <h1 className={`font-normal ${isSmallScreen ? 'text-xs' : 'text-sm'} text-white italic text-left my-2`}>  {t(`l1-5-heading`)} </h1>
        </div>
        <div className='grid grid-cols-2 gap-3 mt-2'>

          {firstFiveInputQuestions.map((question, index) => (
            <div key={`${question.keyName}-${index}`} className="w-full mb-2 text-left">
              <label htmlFor={question.keyName} className={`font-normal text-[14px] text-white italic text-left my-2`}>
                {t(`inputQuestions.${question?.keyName}`)}
              </label>
              <input
                id={question.keyName}
                type="text"
                className="w-full simurg_input border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring focus:ring-indigo-500 focus:border-indigo-500"
                value={formik.values[question.keyName]}
                onChange={(e) => formik.setFieldValue(question.keyName, e.target.value)}
                onBlur={() => formik.setFieldTouched(question.keyName)}
              />
              {formik.touched[question.keyName] && formik.errors[question.keyName] && (
                <p className="mt-1 text-sm text-red-500">{formik.errors[question.keyName]}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProfileFormOne;
