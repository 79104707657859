import React, { useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

export const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    const handlePrevious = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    const renderPageNumbers = () => {
        const pages = [];
        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(
                    <button
                        key={i}
                        onClick={() => onPageChange(i)}
                        className={`px-2 py-1 sm:px-4 sm:py-2 text-sm sm:text-lg border-2 border-solid border-bordercolor rounded-xl ${i === currentPage
                            ? " bg-[#f9dcd4] text-[#000] cursor-not-allowed"
                            : " bg-transparent text-white hover:bg-[#f9dcd4] hover:text-[#000]"
                            }`}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            pages.push(
                <button
                    key={1}
                    onClick={() => onPageChange(1)}
                    className={`px-2 py-1 sm:px-4 sm:py-2 text-sm sm:text-lg border-2 border-solid border-bordercolor rounded-xl ${currentPage === 1
                        ? " bg-[#f9dcd4] text-[#000] cursor-not-allowed"
                        : " bg-transparent text-white hover:bg-[#f9dcd4] hover:text-[#000]"
                        }`}
                >
                    1
                </button>
            );

            if (currentPage > 3) {
                pages.push(
                    <button
                        key="start-dots"
                        onClick={() => onPageChange(Math.max(currentPage - 3, 1))}
                        className="px-2 py-1 sm:px-4 sm:py-2 text-sm sm:text-lg border-2 border-solid border-bordercolor rounded-xl bg-transparent text-white hover:bg-[#f9dcd4] hover:text-[#000]"
                    >
                        ...
                    </button>
                );
            }

            const start = Math.max(2, currentPage - 1);
            const end = Math.min(totalPages - 1, currentPage + 1);

            for (let i = start; i <= end; i++) {
                pages.push(
                    <button
                        key={i}
                        onClick={() => onPageChange(i)}
                        className={`px-2 py-1 sm:px-4 sm:py-2 text-sm sm:text-lg border-2 border-solid border-bordercolor rounded-xl ${i === currentPage
                            ? " bg-[#f9dcd4] text-[#000] cursor-not-allowed"
                            : " bg-transparent text-white hover:bg-[#f9dcd4] hover:text-[#000]"
                            }`}
                    >
                        {i}
                    </button>
                );
            }

            if (currentPage < totalPages - 2) {
                pages.push(
                    <button
                        key="end-dots"
                        onClick={() => onPageChange(Math.min(currentPage + 3, totalPages))}
                        className="px-2 py-1 sm:px-4 sm:py-2 text-sm sm:text-lg border-2 border-solid border-bordercolor rounded-xl bg-transparent text-white hover:bg-[#f9dcd4] hover:text-[#000]"
                    >
                        ...
                    </button>
                );
            }

            pages.push(
                <button
                    key={totalPages}
                    onClick={() => onPageChange(totalPages)}
                    className={`px-2 py-1 sm:px-4 sm:py-2 text-sm sm:text-lg border-2 border-solid border-bordercolor rounded-xl ${currentPage === totalPages
                        ? " bg-[#f9dcd4] text-[#000] cursor-not-allowed"
                        : " bg-transparent text-white hover:bg-[#f9dcd4] hover:text-[#000]"
                        }`}
                >
                    {totalPages}
                </button>
            );
        }
        return pages;
    };

    return (
        <div className="flex flex-col items-center space-y-3 sm:space-y-4">
            <div className="flex flex-wrap justify-center items-center gap-2 sm:gap-3">
                <button
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                    className={`px-3 py-2 sm:px-4 sm:py-3 text-sm sm:text-lg border-2 border-solid border-bordercolor rounded-xl flex items-center justify-center ${currentPage === 1
                        ? " bg-[#f9dcd4] text-[#000] cursor-not-allowed"
                        : " bg-transparent text-white hover:bg-[#f9dcd4] hover:text-[#000]"
                        }`}
                >
                    <FiChevronLeft />
                </button>
                {renderPageNumbers()}
                <button
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                    className={`px-3 py-2 sm:px-4 sm:py-3 text-sm sm:text-lg border-2 border-solid border-bordercolor rounded-xl flex items-center justify-center ${currentPage === totalPages
                        ? " bg-[#f9dcd4] text-[#000] cursor-not-allowed"
                        : " bg-transparent text-white hover:bg-[#f9dcd4] hover:text-[#000]"
                        }`}
                >
                    <FiChevronRight />
                </button>
            </div>
        </div>
    );
};


