import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
const EmojiDragDrop = ({ formik }) => {
    const { t } = useTranslation()
    const initialSentences = [
        { id: "Animals", textKey: t(`initialSentences.iLoveAnimals`), emoji: null },
        { id: "Sporty", textKey: t(`initialSentences.iAmSporty`), emoji: null },
        { id: "Imaginative", textKey: t(`initialSentences.iHaveImagination`), emoji: null },
        { id: "helpsOthers", textKey: t(`initialSentences.iLikeHelpingOthers`), emoji: null },
        { id: "Music", textKey: t(`initialSentences.iLoveMusic`), emoji: null },
        { id: "Creative", textKey: t(`initialSentences.iAmCreative`), emoji: null },
        { id: "Polite", textKey: t(`initialSentences.iAmPolite`), emoji: null },
        { id: "HelpsAtHome", textKey: t(`initialSentences.iHelpAtHome`), emoji: null },
        { id: "GoodMemory", textKey: t(`initialSentences.iHaveGoodMemory`), emoji: null },
        { id: "SelfCare", textKey: t(`initialSentences.iCareForMyNeeds`), emoji: null },
        { id: "LogicalThinking", textKey: t(`initialSentences.iAmGoodAtLogicalThinking`), emoji: null },
        { id: "AdaptsToNewSituations", textKey: t(`initialSentences.iAdaptToNewSituations`), emoji: null },
        { id: "AttentiveInClass", textKey: t(`initialSentences.iAmAttentiveInClass`), emoji: null },
    ];

    const [sentences, setSentences] = useState(
        formik.values.loves?.length > 0
            ? initialSentences.map((sentence) => {
                const matched = formik.values.loves.find((item) => item.sentence === sentence.id);
                return matched ? { ...sentence, emoji: { symbol: matched.symbol, id: matched.mood } } : sentence;
            })
            : initialSentences
    );

    useEffect(() => {
        const formattedSentences = sentences
            .filter((sentence) => sentence.emoji !== null) // Only include sentences with emojis
            .map((sentence) => ({
                sentence: sentence.id, // Use the `id` as the sentence identifier
                symbol: sentence.emoji.symbol, // Emoji symbol
                mood: sentence.emoji.id, // Emoji id (smile, upset, sad)
            }));

        formik.setFieldValue("loves", formattedSentences);
    }, [sentences]);

    const emojis = [
        { id: "smile", symbol: "😊" },
        { id: "upset", symbol: "😐" },
        { id: "sad", symbol: "😟" },
    ];

    const handleDragStart = (e, emoji) => {
        e.dataTransfer.setData("emoji", JSON.stringify(emoji));
    };

    const handleDrop = (e, sentenceId) => {
        const emoji = JSON.parse(e.dataTransfer.getData("emoji"));
        setSentences((prev) =>
            prev.map((sentence) =>
                sentence.id === sentenceId ? { ...sentence, emoji } : sentence
            )
        );
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    return (
        <div className="p-2 items-center -mt-8">
            <h2 className="text-[14px] font-[500] text-white mb-2 text-center">
                {t(`l5-8-mode1`)}
                <br />
                {t(`l5-8-mode2`)} <br />
                {t(`l5-8-mode3`)}
            </h2>

            <div className="flex items-center justify-center gap-4 mb-2">
                {emojis.map((emoji) => (
                    <div
                        key={emoji.id}
                        draggable
                        onDragStart={(e) => handleDragStart(e, emoji)}
                        className="text-[24px] cursor-pointer"
                    >
                        {emoji.symbol}
                    </div>
                ))}
            </div>

            <div className="max-h-[50vh] overflow-auto pr-3 no-scrollbar">
                {sentences.map((sentence) => (
                    <div
                        key={sentence.id}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, sentence.id)}
                        className="flex justify-between w-full py-1"
                    >
                        <span className="text-[12px] text-white">  {sentence.textKey}</span>
                        <div
                            className={`w-2 h-2 flex justify-center items-center border rounded-lg ${sentence.emoji ? "bg-transparent" : "bg-transparent"
                                }`}
                        >
                            {sentence.emoji ? sentence.emoji.symbol : "➕"}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EmojiDragDrop;
