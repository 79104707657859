import React from "react";
import { stressOptions } from "../../common/constant/data";
import { useTranslation } from "react-i18next";
const StrengthForm = ({ formik }) => {

    const { t } = useTranslation()
    return (
        <div className="flex flex-col gap-2 text-white">
            <p className="text-[13px] font-bold italic text-white">
                {t(`l2-8-desc`)}

            </p>
            <p className="text-[14px] font-bold text-left ">
                {t(`l2-8-heading`)}
            </p>

            {stressOptions.map((option, index) => (
                <div key={index} className="w-full">
                    <div className="flex justify-between items-baseline">
                        <label className="flex items-center">
                            <div className="w-4 h-4">
                                <input
                                    type="checkbox"
                                    checked={formik.values.sourcesOfStrength.includes(option.value)}
                                    onChange={() => {
                                        const selected = [...formik.values.sourcesOfStrength];
                                        if (selected.includes(option.value)) {
                                            formik.setFieldValue(
                                                "sourcesOfStrength",
                                                selected.filter((item) => item !== option.value)
                                            );
                                        } else {
                                            formik.setFieldValue("sourcesOfStrength", [
                                                ...selected,
                                                option.value,
                                            ]);
                                        }
                                    }}
                                    className="w-full h-full radio-circle text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                />
                            </div>
                            <span className={`ml-4 self-center text-left text-[13px] ${formik.values.sourcesOfStrength.includes(option.value)
                                ? "text-green-600"
                                : "text-orange-600"
                                } `}>{t(`stressOptions.${option.questionKey}`)}</span>
                        </label>

                        {option.value === "Other" &&
                            formik.values.sourcesOfStrength.includes("Other") && (
                                <input
                                    type="text"
                                    className="w-[70%] border-b border-white bg-transparent text-white outline-none focus:border-blue-500 py-1 ml-4"
                                    placeholder=""
                                    value={formik.values.otherSourcesOfStrength}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            "otherSourcesOfStrength",
                                            e.target.value
                                        )
                                    }
                                />
                            )}
                    </div>
                </div>
            ))}

            {formik.errors.sourcesOfStrength && formik.touched.sourcesOfStrength && (
                <p className="text-red-500 text-[10px] mt-1 text-left">{formik.errors.sourcesOfStrength}</p>
            )}

            <div className={`mt-2 !text-center`}>
                <p className={` text-sm font-bold italic text-white `}> {t(`l2-8-para`)}</p>
                <p className={` text-sm font-bold italic text-white `}>{t(`l2-8-para_one`)}</p>
                <p className={` text-sm font-bold italic text-white `}>{t(`l2-8-para_two`)}</p>
            </div>
        </div>
    );
};

export default StrengthForm;
