import React, { useEffect, useRef, useState } from 'react';
import ellipseBG from '../../assets/EllipseBG.png';
import Intial from './forms/intial';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { getValidationSchema } from '../LevelThree/constant';
import { useSelector, useDispatch } from 'react-redux';
import ScalarForm, { sliderConfig } from '../common/forms/ScalarForm';
import LevelRadioSelectionlastSeven from '../common/forms/LevelRadioSelectionlastSeven';
import {
  firstSevenQuestions,
  getInitialValues,
  lastSevenQuestions
} from '../common/constant/data';
import LevelRadioSelection from '../common/forms/LevelRadioSelection';
import { handleAddedPersonalInfoAction } from '../../redux/Started/apisActions';
import { appImages } from '../../assets';
import {
  exercise_list,
  exercise_reduce_list,
  exercise_save_list
} from './constant';
import Spiner from '../common/constant/spiner';
import { useTranslation } from "react-i18next";
import { setUserLevelSixInfo } from '../../redux/Started';
import OverviewMail from '../common/forms/overviewMail';
import ModuleNav from '../common/forms/moduleNav';
import DEPdf from '../../assets/files/Literaturliste_DE_9.1.2025.pdf'
import BKSPdf from '../../assets/files/Literaturliste_BKS_9.1.2025.pdf'
import TRPdf from '../../assets/files/Literaturliste_TR_9.1.2025.pdf'
import PDFIcon from '../../assets/pdf.png'

const LevelSix = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [renderForm, setRenderForm] = useState(0);
  const [validationSchema, setValidationSchema] = useState(
    getValidationSchema(renderForm, t)
  );
  const currentLanguage = useSelector((state) => state.language);
  useEffect(() => {
    setValidationSchema(getValidationSchema(renderForm - 1, t)); // Update schema when renderForm changes
  }, [renderForm]);
  const { levelSixForm, loading } = useSelector(
    (state) => state.initialUserInfo
  );
  const { userID } = useSelector((state) => state.user);
  console.log({ userID });
  const levelSixInitialValues = {
    ...sliderConfig.reduce(
      (values, slider) => ({
        ...values,
        [slider.name]: 0
      }),
      {}
    ),
    ...firstSevenQuestions.reduce(
      (values, question) => ({
        ...values,
        [question.keyName]: '' // Default value for each question
      }),
      {}
    ),
    ...lastSevenQuestions.reduce(
      (values, question) => ({
        ...values,
        [question.keyName]: '' // Default value for each question
      }),
      {}
    ),
    distractionMethods: Array.from({ length: 8 }, () => ({
      person: '',
      text: ''
    })),
    stressfulSituations: '',
    pickleJar: 'bx,mnkjbcmn njc'
  }
  const formik = useFormik({
    initialValues: getInitialValues(levelSixInitialValues, levelSixForm),
    validationSchema,
    onSubmit: (values) => {
      const data = {
        userId: userID || '',
        LevelSix: JSON.stringify(values)
      };
      if (renderForm === 12) {
        dispatch(setUserLevelSixInfo(values));
        dispatch(
          handleAddedPersonalInfoAction({
            data,
            // popup: true,
            // callback: (res) => setIsVisible(true)
            callback: (res) => setRenderForm(renderForm + 1)
          })
        );
      } else {
        if (renderForm == 16) {
          navigate('/')
        }
        formik.setTouched({});
        setRenderForm(renderForm + 1);
      }
    }
  });

  return (
    <>
      <div className='self-center pt-4 text-center '>
        {renderForm === 2 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic' >
              {t(`l1-2-title`)}
            </h1>
            <h1 className='text-[14px] text-mainBlue font-bold italic ' >
              {t(`l1-2-title-one`)}

            </h1>
            <h1 className='text-[14px] text-mainBlue font-semibold' >    {t(`l1-2-title-two`)}</h1>
          </>
        )}
        {renderForm === 0 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              Level 6
            </h1>
            <h1 className='text-[16px] text-mainBlue font-bold italic '>
              {t(`l6-0-title`)} <br />{' '}
              {t(`l6-0-title_one`)}
            </h1>
            <h1 className='text-[16px] text-mainBlue font-semibold'>
              {t(`l6-0-title_two`)}
            </h1>
          </>
        )}
        {renderForm === 4 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              {t(`l6-4-title`)}
            </h1>
          </>
        )}
        {renderForm === 5 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              {' '}
              {t(`l6-5-title`)}
            </h1>
          </>
        )}
        {renderForm === 6 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              {t(`l6-6-title`)}
            </h1>
          </>
        )}
        {renderForm === 7 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              {t(`l6-7-title`)}
            </h1>
          </>
        )}
        {renderForm === 8 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              {t(`l6-8-title`)}
            </h1>
          </>
        )}
        {renderForm === 9 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              {t(`l6-9-title`)}
            </h1>
          </>
        )}
        {renderForm === 10 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              {t(`l6-10-title`)}
            </h1>
          </>
        )}
        {renderForm === 11 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              {t(`l6-11-title`)}
            </h1>
          </>
        )}
        {renderForm === 12 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              {' '}
              {t(`l6-12-title`)} <br />
              {t(`l6-12-title_one`)}
            </h1>
          </>
        )}

        {renderForm === 13 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic' >{t(`overview_title_super`)} <br /> {t(`introduction_l6_title`)}   </h1>
          </>
        )}
        {renderForm === 14 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`overview_title`)}   </h1>
          </>
        )}
        {renderForm === 15 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`overview_title`)}  <br /> Level 6  </h1>
          </>
        )}
        {renderForm === 16 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`lit_title`)}  </h1>
          </>
        )}

      </div>
      <div
        style={{ background: `url(${ellipseBG})` }}
        className='min-h-[85vh] !bg-cover !bg-no-repeat !bg-top relative'
      >
        <div className='relative pt-20 w-full text-white max-w-[36rem] mx-auto flex flex-col justify-between h-full justify-self-center text-center py-2'>
          {/* <Intial /> */}
          <div className='max-w-[32rem] mx-auto'>
            {renderForm === 0 && <Intial />}
            {renderForm === 1 && <ScalarForm formik={formik} />}
          </div>

          {renderForm === 2 && <LevelRadioSelection formik={formik} />}

          {renderForm === 3 && <LevelRadioSelectionlastSeven formik={formik} />}

          {renderForm === 4 && (
            <div className='flex flex-col gap-5 justify-start items-start'>
              <p className='text-[16px] font-bold italic'>
                {t(`l6-4-desc`)}
              </p>
              <div className='mt-2 max-w-[270px] mx-auto'>
                <video className='w-full h-auto ' controls>
                  <source src={t(`video_link_16`)} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          )}

          {renderForm === 5 && (
            <div className='flex flex-col gap-5 justify-start items-start'>
              <p className='text-[16px] font-bold italic'>
                {t(`l6-5-desc`)}
              </p>
              <div className='mt-2 max-w-[270px] mx-auto'>
                <video className='w-full h-auto ' controls>
                  <source src={t(`video_link_17`)} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          )}

          {renderForm === 6 && (
            <div className='flex flex-col gap-2 justify-center items-center'>
              <p className='text-[14px] font-bold italic'>
                {t(`l6-6-heading`)}
              </p>
              <p className='text-[14px] font-bold italic'>
                {t(`l6-6-heading_two`)}
              </p>
              <div className='grid md:grid-cols-1 grid-cols-1 gap-2'>
                {formik?.values?.distractionMethods?.map((method, index) => (
                  <div key={index} className='flex gap-4 px-6'>
                    <input
                      type='text'
                      className='w-1/3 p-2 border bg-[#D9D9D9] text-black'
                      value={formik.values.distractionMethods[index].person}
                      onChange={(e) =>
                        formik.setFieldValue(
                          `distractionMethods[${index}].person`,
                          e.target.value
                        )
                      }
                      onBlur={() =>
                        formik.setFieldTouched(
                          `distractionMethods[${index}].person`,
                          true
                        )
                      }
                    />
                    <input
                      type='text'
                      className='w-2/3 p-2 border bg-[#D9D9D9] text-black'
                      value={formik.values.distractionMethods[index].text}
                      onChange={(e) =>
                        formik.setFieldValue(
                          `distractionMethods[${index}].text`,
                          e.target.value
                        )
                      }
                      onBlur={() =>
                        formik.setFieldTouched(
                          `distractionMethods[${index}].text`,
                          true
                        )
                      }
                    />
                  </div>
                ))}
              </div>
              {/* <p className="text-[14px] font-bold italic">· Ich bin sprachlich kreativ</p> */}

              {/* <p className="text-[14px] font-bold italic">· Ich kenne meine Fähigkeiten</p>
                            <p className="text-[14px] font-bold italic">
                                Schreibe in die Kästchen den Namen einer Person, die Du sehr magst. Schreibe dann neben jedem Buchstaben eine Fähigkeit oder eine positive Eigenschaft von Dir selbst!
                            </p> */}
            </div>
          )}

          {renderForm === 7 && (
            <div className='flex flex-col gap-5 justify-start items-center '>
              <p className='text-[13px] font-bold italic'>
                {t(`l6-7-heading`)}
              </p>
              <p className='text-[13px] font-bold italic'>
                {t(`l6-7-desc`)}
              </p>
              <div className='grid md:grid-cols-2 grid-cols-1 gap-2'>
                {exercise_list.map((item, index) => (
                  <div key={index} className='w-full'>
                    <div className={`flex items-center flex-row gap-2`}>
                      <p className='text-[13px] italic font-medium text-left w-3/5'>
                        {t(`exerciseList.${item.titleKey}`)}
                      </p>
                      <img
                        src={item.img}
                        alt={item.title}
                        className='w-32 h-24 object-contain'
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {renderForm === 8 && (
            <div className='flex flex-col gap-5'>
              <p className='text-[14px] font-bold italic'>
                {t(`l6-8-desc`)}
              </p>
              <div className='flex gap-5 items-center'>
                <p className='text-[13px] font-bold italic w-3/5 text-left'>
                  <div className="max-w-[200px]">
                    {t(`l6-8-para1`)}
                  </div>
                  {/* Atme durch die Nase <br />
                  langsam <br />
                  ein und halte Deinen <br />
                  Atem 5 Sekunden lang an, <br />
                  zähle dabei langsam bis 5. <br />
                  {`--- ->1-2-3-4-5`} <br />
                  Jetzt stelle Dir bitte vor, <br />
                  dass Du mit jedem Atemzug <br />
                  langsam aber kräftig Deine <br />
                  Sorgen aus Deinem Mund <br />
                  weit weg ausatmest.
                  <br /> */}
                  <br />
                  <br />
                  <span className='text-[13px]'>
                    {t(`l6-8-para2`)}
                  </span>
                </p>
                <img
                  src={appImages.relax}
                  alt='Relax'
                  className='w-32 h-80 object-contain'
                />
              </div>
            </div>
          )}

          {renderForm === 9 && (
            <div className='flex flex-col gap-5'>
              <p className='text-[14px] font-bold italic'>
                {t(`l6-9-heading`)}
              </p>
              <div className='grid md:grid-cols-2 grid-cols-1 gap-2'>
                {exercise_reduce_list.map((item, index) => (
                  <div key={index} className='w-full'>
                    <div className={`flex items-center justify-between  gap-2`}>
                      <a
                        target='_blank'
                        className='text-[11px] italic font-medium text-left underline cursor-pointer'
                        href={t(`${item.link}`)}
                      >
                        {t(`exerciseReduceList.${item.titleKey}`)}
                      </a>
                      <img
                        src={item.img}
                        alt={item.titleKey}
                        className='w-32 h-20 object-contain'
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {renderForm === 10 && (
            <div className='flex flex-col gap-5'>
              <p className='text-[14px] font-bold italic'>
                {t(`l6-10-heading`)}
              </p>

              <div className='flex gap-5 items-center justify-between'>
                <ul className='w-3/5'>
                  {exercise_save_list.map((item) => (
                    <li
                      key={item.title}
                      className='text-[12px] text-left font-bold italic mt-2'
                    >
                      {t(`exerciseSaveList.${item.titleKey}`)}
                    </li>
                  ))}
                  <li className='text-[12px] text-left font-bold italic mt-2'>
                    {t(`l6-10-para5`)}
                  </li>
                </ul>
                <div className='relative'>
                  <img
                    src={appImages.glass}
                    alt='Glass'
                    className='w-44 h-56 object-contain'
                  />
                  <p className='text-[14px] text-black font-[500] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] '>
                    {t('l6-11-title-place')}
                  </p>
                </div>
              </div>
            </div>
          )}

          {renderForm === 11 && (
            <div className='flex flex-col gap-5 text-center'>
              <p className='text-[16px] font-bold italic'>
                {t(`l6-11-heading`)}
              </p>
              <p className='text-[15px] font-bold italic'>
                {t(`l6-11-desc1`)}

              </p>
              <p className='text-[15px] font-bold italic'>
                {t(`l6-11-desc2`)}
              </p>
              <p className='text-[15px] font-bold italic'>
                {t(`l6-11-desc3`)} <a
                  target='_blank'
                  className='underline cursor-pointer'
                  href={t(`drive_link_26`)}
                >
                  {t(`l6-11-desc3_link`)}
                </a>{' '}

                {t(`l6-11-desc3_two`)}
              </p>
            </div>
          )}

          {renderForm === 12 && (
            <div className='space-y-6 text-center'>

              <p className='text-[16px] font-bold italic'>
                <span className='text-[18px]' >{t(`l6-12-desc1_head`)}</span> <br />
                {t(`l6-12-desc1`)}
              </p>
              <p className='text-[16px] font-bold italic'>
                {t(`l6-12-desc2`)}
              </p>
              <p className='text-[16px] font-bold italic'>
                {t(`l6-12-desc3`)}
              </p>
              <textarea
                className='w-full p-3 border rounded-md text-black border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none'
                rows='5'
                value={formik.values.stressfulSituations}
                onChange={(e) =>
                  formik.setFieldValue('stressfulSituations', e.target.value)
                }
                onBlur={() =>
                  formik.setFieldTouched('stressfulSituations', true)
                }
                placeholder={t(`l6-12-placeholder`)}
              ></textarea>
              {formik.touched.stressfulSituations &&
                formik.errors?.stressfulSituations && (
                  <p className='text-sm text-red-500'>
                    {formik.errors.stressfulSituations}
                  </p>
                )}
            </div>
          )}

          {renderForm === 13 && <OverviewMail />}
          {renderForm === 14 && <ModuleNav />}
          {renderForm === 15 && <div className='text-left' >

            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`}  >
              {t(`overview_module_start_six`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
              {t(`overview_module_1_six`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
              {t(`overview_module_2_six`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
              {t(`overview_module_3_six`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
              {t(`overview_module_4_six`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
              {t(`overview_module_5_six`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
              {t(`overview_module_6_six`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
              {t(`overview_module_7_six`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
              {t(`overview_module_8_six`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
              {t(`overview_module_9_six`)}
            </p>
          </div>}
          {renderForm === 16 && <div className='text-left' >

            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer mb-2`}  >
              {t(`lit_list_one`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer mb-2`} >
              {t(`lit_list_two`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer mb-2`} >
              {t(`lit_list_three`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer mb-2`} >
              {t(`lit_list_four`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer mb-2`} >
              {t(`lit_list_five`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer mb-2`} >
              {t(`lit_list_seven`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer mb-2`} >
              {t(`lit_list_eight`)}
            </p>

          </div>}



          <div className='pt-4 flex gap-2 mb-2'>
            <button
              className='w-full button_primary'
              onClick={() => {
                if (renderForm === 0) {
                  navigate('/level-five?render=13');
                } else {
                  setRenderForm(renderForm - 1);
                }
              }}
            >
              {t(`s-2-btn1`)}
            </button>
            <button
              className='w-full button_secodry relative'
              onClick={() => {
                formik.handleSubmit();
              }}
              disabled={loading}
            >
              {loading && <Spiner />}
              <span className={loading ? 'opacity-0' : ''}> {renderForm == 12 ? 'Beenden' : t(`s-2-btn2`)} </span>
            </button>
          </div>
        </div>
      </div>

      {isVisible && (
        <div
          className='fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50'
        // onClick={handleClosePopup}
        >
          <div
            className='bg-[#2C2954] p-8 rounded-lg shadow-lg max-w-md w-full text-center'
            onClick={(e) => e.stopPropagation()}
          >
            {/* <p className='text-[24px] text-white font-bold mb-4'>
              Thank you for your response
            </p> */}
            <div className="flex items-center gap-4 justify-center my-5">
              <img src={PDFIcon} alt="" />
              <a className='text-white text-[16px]' target='_blank' href={currentLanguage?.language == 'de' ? DEPdf : currentLanguage?.language == 'hr' ? BKSPdf : TRPdf} >  literature lists </a>
            </div>
            <button onClick={() => navigate('/')} className='button_primary'>
              Back to Home
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default LevelSix;
