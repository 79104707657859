import React from "react";
import { levelOneMcqs, levelOneMcqs_options } from "../../common/constant/data";
import { useTranslation } from 'react-i18next';

const StressForm = ({ formik }) => {
    const { t } = useTranslation()
    return (
        <div className="flex flex-col gap-1 w-[33rem]">
            <p className="text-[13px] font-medium text-white text-center mb-2" >
                {t(`l1-7-desc`)}
            </p>
            {levelOneMcqs.map((question, index) => (
                <div key={index} className="w-full">
                    <p className="text-[13px] font-medium text-white text-left"> {t(`levelOneMcqs.${question?.keyName}`)}</p>
                    <div className="flex flex-wrap gap-1 justify-between">
                        {levelOneMcqs_options.map((option, i) => (
                            <div key={`${i}-${question.question}`} className="flex items-center gap-2">
                                <input
                                    type="checkbox"
                                    id={`checkbox-${index}-${i}`}
                                    className="form-checkbox radio-circle text-green-500"
                                    checked={formik.values.answers?.[index]?.checked || false}
                                    onChange={() =>
                                        formik.setFieldValue(
                                            `answers[${index}].checked`,
                                            !formik.values.answers[index]?.checked
                                        )
                                    }
                                />
                                <label
                                    htmlFor={`checkbox-${index}-${i}`}
                                    className="text-sm text-gray-200"
                                >
                                    {t(`levelOneMcqs_options.yes`)}

                                </label>
                            </div>
                        ))}
                        {!formik.values.answers[index]?.checked == false && (
                            <div className="w-1/2">
                                <input
                                    type="text"
                                    placeholder={t(`levelOneMcqsOption.${question?.keyName}`)}
                                    className={`w-full border ${formik.touched.answers?.[index]?.details &&
                                        formik.errors.answers?.[index]?.details
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        } rounded-md px-3 py-2 mt-2 !text-black ${formik.values.answers[index]?.checked
                                            ? "bg-white"
                                            : "bg-gray-200"
                                        } focus:outline-none focus:ring focus:ring-indigo-500`}
                                    value={formik.values.answers[index]?.details || ""}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            `answers[${index}].details`,
                                            e.target.value
                                        )
                                    }
                                    onBlur={() =>
                                        formik.setFieldTouched(`answers[${index}].details`, true)
                                    }
                                />
                                {formik.touched.answers?.[index]?.details &&
                                    formik.errors.answers?.[index]?.details && (
                                        <p className="text-[10px] text-left text-red-500 mt-1">
                                            {formik.errors.answers[index]?.details}
                                        </p>
                                    )}
                            </div>
                        )}

                    </div>
                </div>
            ))}
            {formik.errors.answers && typeof formik.errors.answers === "string" && (
                <p className="text-[10px] text-left text-red-500 mt-1">{formik.errors.answers}</p>
            )}

        </div>
    );
};

export default StressForm;
