export const gender = [
    { label: 'male', value: 'MALE', keyName: 'male' },
    { label: 'female', value: 'FEMALE', keyName: 'female' },
    { label: 'other', value: 'OTHER', keyName: 'other' },
];

export const country = [
    // { label: 'burgenland', value: 'BURGENLAND', keyName: 'burgenland' },
    { label: 'carinthia', value: 'CARINTHIA', keyName: 'carinthia' },
    { label: 'lowerAustria', value: 'LOWER_AUSTRIA', keyName: 'lowerAustria' },
    { label: 'upperAustria', value: 'UPPER_AUSTRIA', keyName: 'upperAustria' },
    { label: 'salzburg', value: 'SALZBURG', keyName: 'salzburg' },
    { label: 'styria', value: 'STYRIA', keyName: 'styria' },
    { label: 'tyrol', value: 'TYROL', keyName: 'tyrol' },
    { label: 'vorarlberg', value: 'VORARLBERG', keyName: 'vorarlberg' },
    { label: 'vienna', value: 'VIENNA', keyName: 'vienna' },
];

export const questionsDetailsOne = [
    {
        id: 'hospitalCare',
        text: 'hospitalCare',
        keyName: 'hospitalCare',
    },
    {
        id: 'psychiatricCare',
        text: 'psychiatricCare',
        keyName: 'psychiatricCare',
    },
    {
        id: 'psychologicalCare',
        text: 'psychologicalCare',
        keyName: 'psychologicalCare',
    },
];

export const questionsDetailsSecond = [
    {
        id: 'psychotherapy',

        keyName: 'psychotherapy',
    },
    {
        id: 'psychiatricDiagnosis',
        keyName: 'psychiatricDiagnosis',
    },
];