import React from 'react'
import techVideo from '../../../assets/tech.mov'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

const VideoScreen = ({ setRenderForm }) => {
    const navigate = useNavigate();
    const isSmallScreen = window.innerHeight <= 600;
    const { t } = useTranslation()
    const currentLanguage = useSelector((state) => state.language);

    return (
        <>
            <div>
                <h1 className={`font-medium text-white ${isSmallScreen ? 'text-xs' : 'text-sm'} `}>
                    {t(`l1-10-desc`)}
                </h1>

                {/* Video section */}
                <div className='mt-4'>
                    <video
                        className={`w-full ${isSmallScreen ? 'h-[100px]' : 'h-auto'} max-w-[300px] mx-auto`}
                        controls

                    >
                        <source src={t(`video_link_3`)} type="video/mp4" className='h-full' />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <h1 className={`font-bold italic ${isSmallScreen ? 'text-[10px]' : 'text-xs'} mt-6 text-white`}>{t(`l1-10-para1`)} <a href={t(`drive_link_27`)} target='_blank' className=' underline' >{t(`l1-10-para1_link`)}</a>  {t(`l1-10-para1_two`)}</h1>
                <h1 className={`font-bold text-white ${isSmallScreen ? 'text-[10px]' : 'text-xs'}  mt-4`}>{t(`l1-10-para2`)}<br />
                    {t(`l1-10-para2Des`)}
                    <br />
                    {currentLanguage?.language == 'tr' ? 'şimdi Level 2`ye geçebilirsin' : t(`l1-10-para2DesOne`)}
                    {/* {t(`l1-10-para2DesOne`)} */}
                    {/* {t(`l1-10-para2Des`)} */}
                </h1>

            </div>
        </>
    )
}

export default VideoScreen