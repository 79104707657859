import React from 'react'
import { useTranslation } from "react-i18next";
const OverviewMail = () => {
    const { t } = useTranslation()
    return (
        <div>
            <p className={`text-xl font-bold text-white`}>
                {t(`introduction_des`)} <a href={`mailto:${t(`introduction_email`)}`} > {t(`introduction_email`)} </a> {t(`introduction_des_2`)}
            </p>
        </div>
    )
}

export default OverviewMail
