import React, { useEffect, useRef, useState } from 'react';
import ellipseBG from '../../assets/EllipseBG.png'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { login } from '../../redux/auth/loginSlice';
import { useNavigate } from 'react-router-dom';
import { post } from '../../utils/axios'
import { Bounce, toast } from 'react-toastify';
import Spiner from '../common/constant/spiner';



const Login = () => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialValues = {
        email: '',
        password: '',
    };

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string().min(6, 'Minimum 6 characters').required('Required'),
    });

    const handleSubmit = async (values) => {
        const data = {
            email: values.email,
            password: values.password,
        }
        setLoading(true)
        try {
            const response = await post(`/auth/signin`, data, {});
            // toast.success(``, {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            //     transition: Bounce,
            // })
            // console.log("data in response", response)
            dispatch(login(response));
            navigate('/dashboard');
            setLoading(false)
        } catch (err) {
            console.log(err.response?.data?.message);
            // Return or throw an error if needed
            throw err;
        }
    };
    return (
        <>
            <div className='self-center pt-4 text-center '>
            </div>
            <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} className="">
                    {() => (
                        <Form className='text-left h-full px-4' >
                            <div className='relative pt-20  w-full max-w-[36rem] flex flex-col justify-center h-full justify-self-center text-center py-2'>
                                <div className="text-left">
                                    {/* <h2 className='text-white font-bold text-3xl mb-5 text-center' >Login</h2> */}


                                    <div className='my-4' >
                                        <label className={`font-normal  text-[14px] text-white   my-2`}>
                                            Email
                                        </label>
                                        <Field type="email" name="email" className="w-full simurg_input border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring focus:ring-indigo-500 focus:border-indigo-500" />
                                        <ErrorMessage name="email" component="div" className='mt-1 text-sm text-red-500' />
                                    </div>
                                    <div>
                                        <label className={`font-normal  text-[14px] text-white   my-2`}>
                                            Password
                                        </label>
                                        <Field type="password" name="password" className="w-full simurg_input border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring focus:ring-indigo-500 focus:border-indigo-500" />
                                        <ErrorMessage name="password" component="div" className='mt-1 text-sm text-red-500' />
                                    </div>
                                    <div className='pt-8 mb-2 flex justify-end'>
                                        <button className='w-full button_secodry relative' type='submit'
                                            disabled={loading}
                                        >

                                            {loading && (
                                                <Spiner />
                                            )}
                                            <span className={loading ? 'opacity-0' : ''}>Login</span>

                                        </button>
                                    </div>
                                </div>

                                <div className='pt-4 mb-2 flex gap-2'>
                                    {/* <button className='w-full button_secodry relative' type='submit'
                                        disabled={loading}
                                    >

                                        {loading && (
                                            <Spiner />
                                        )}
                                        <span className={loading ? 'opacity-0' : ''}>Login</span>

                                    </button> */}
                                </div>
                            </div>

                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default Login
