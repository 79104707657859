import React from 'react'
import mainBG from '../../../assets/EinführungMainBG.png'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "../../../redux/language/languageSlice";
import GrFlag from '../../../assets/icons/germany_flag.svg'
import TrFlag from '../../../assets/icons/turkey_flag.svg'
import HrFlag from '../../../assets/icons/croatia_flag.svg'

const OnlineGuide = ({ nextClick }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const currentLanguage = useSelector((state) => state.language);
    const handleLanguageChange = (lng) => {
        i18n.changeLanguage(lng);
        dispatch(setLanguage(lng)); // Persist in Redux
    };
    return (
        <>
            <div style={{ background: `url(${mainBG})` }} className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between relative'>
                <div className="absolute min-w-60 top-3 right-2">
                    <div className="flex gap-1 button_secodry justify-center items-center p-2 rounded-md">
                        <button className={`flex items-center font-bold gap-2 w-12 mx-2 text-[20px] ${currentLanguage?.language == 'de' ? 'text-mainBlue' : ''} `} onClick={() => handleLanguageChange('de')}  >
                            {/* <img src={GrFlag} className='w-6' alt="" /> */}
                            DE</button>
                        <button className={`flex items-center font-bold gap-2 w-12 mx-2 text-[20px] ${currentLanguage?.language == 'hr' ? 'text-mainBlue' : ''} `} onClick={() => handleLanguageChange('hr')} >
                            {/* <img src={HrFlag} className='w-6' alt="" /> */}
                            BKS</button>
                        <button className={`flex items-center font-bold gap-2 w-12 mx-2 text-[20px] ${currentLanguage?.language == 'tr' ? 'text-mainBlue' : ''} `} onClick={() => handleLanguageChange('tr')}  >
                            {/* <img src={TrFlag} className='w-6' alt="" /> */}
                            TR</button>
                    </div>
                    {/* <select
                        id="language"
                        value={currentLanguage.language}
                        onChange={(e) => handleLanguageChange(e.target.value)}
                        className="block w-full p-2 border border-gray-300 rounded-lg bg-white text-black shadow-sm focus:ring focus:ring-white focus:border-white"
                    >
                        <option value="" disabled>
                            Choose a language
                        </option>
                        <option value="de">German</option>
                        <option value="hr">Croatian</option>
                        <option value="tr">Turkish</option>
                    </select> */}

                </div>
                <div className="">
                    <div className="mt-8 text-center text-mainBlue text-6xl font-medium">
                        My Simurg Guide
                    </div>
                    <div className="mt-2 text-center text-mainBlue text-2xl font-medium">
                        {t(`s-0-title`)}
                    </div>

                </div>

                <div className='py-8 mx-auto'>
                    <div className="bordered-text">
                        Discover the power in you!
                    </div>
                    <button className='w-full button_secodry min-w-[300px] button_secondry_bg' onClick={() => nextClick()}> {t(`s-1-btn`)} </button>
                </div>
            </div>
        </>
    )
}

export default OnlineGuide