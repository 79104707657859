import * as Yup from 'yup';
import { sliderConfig } from '../../common/forms/ScalarForm';
import { firstSevenQuestions, lastSevenQuestions, firstFiveInputQuestions, lastFiveInputQuestions, DATA_Playlist } from '../../common/constant/data';





export const getValidationSchema = (renderForm, t) => {
  if (renderForm === 0) {
    return Yup.object(
      sliderConfig.reduce((schema, slider) => {
        schema[slider.name] = Yup.number()
          .required(t(`levelOneError.error_one`))
          .min(slider.minValue, `${t(`levelOneError.error_two`)} ${slider.minValue}.`)
          .max(slider.maxValue, `${t(`levelOneError.error_four`)} ${slider.maxValue}.`);
        return schema;
      }, {}),
    );
  } else if (renderForm === 1) {
    return Yup.object(
      firstSevenQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          t(`levelOneError.error_three`),
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 2) {
    return Yup.object(
      lastSevenQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          t(`levelOneError.error_three`),
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 3) {
    return Yup.object(
      firstFiveInputQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string();
        // .required(
        //   t(`levelOneError.errroOne`),
        // );
        return schema;
      }, {}),
    );
  } else if (renderForm === 4) {
    return Yup.object(
      lastFiveInputQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string();
        // .required(
        //   t(`levelOneError.errroOne`),
        // );
        return schema;
      }, {}),
    );
  } else if (renderForm === 5) {
    return Yup.object().shape({
      answers: Yup.array()
      // .of(
      //   Yup.object().shape({
      //     checked: Yup.boolean(),
      //     details: Yup.string().test(
      //       'is-checked-input-required',
      //       'Bitte fülle dieses Feld aus.', // This is what should be returned if invalid
      //       function (value) {
      //         const { checked } = this.parent;
      //         return !checked || (value && value.trim() !== '');
      //       },
      //     ),
      //   }),
      // )
      // .test(
      //   'at-least-one-checked',
      //   'Bitte wähle mindestens eine Option aus.', // Message if no checkbox is checked
      //   function (answers) {
      //     // Check if at least one object in the array has `checked: true`
      //     return answers && answers.some(answer => answer.checked);
      //   },
      // ),
    });
  } else if (renderForm === 6) {
    return Yup.object(
      DATA_Playlist[0].data.reduce((schema, item) => {
        schema[item.keyName] = Yup.string();
        // .required(
        //   t(`levelOneError.errroOne`),
        // );
        return schema;
      }, {}),
    );
  }

  // Add other cases as needed
  return Yup.object(); // Default empty schema
};


export const transformData = formikValues => {
  return {
    stressFeel: formikValues.stressFeel,
    stressTolerance: formikValues.stressTolerance,
    comfortable: formikValues.comfortable,
    relaxed: formikValues.relaxed,
    sleepEnough: formikValues.sleepEnough,
    enoughToDrinkAndEat: formikValues.enoughToDrinkAndEat,
    concentrateWell: formikValues.concentrateWell,
    learnEnough: formikValues.learnEnough,
    tookBreak: formikValues.tookBreak,
    calmAndComposed: formikValues.calmAndComposed,
    enjoyedHobbies: formikValues.enjoyedHobbies,
    sensedOverload: formikValues.sensedOverload,
    didntBrood: formikValues.didntBrood,
    enjoyedLife: formikValues.enjoyedLife,
    enjoyedSocializing: formikValues.enjoyedSocializing,
    confidentAboutProject: formikValues.confidentAboutProject,
    positiveBehaviors: formikValues.positiveBehaviors,
    badHabits: formikValues.badHabits,
    strengths: formikValues.strengths,
    weaknesses: formikValues.weaknesses,
    hobbies: formikValues.hobbies,
    enjoyBeingWith: formikValues.enjoyBeingWith,
    proudOf: formikValues.proudOf,
    sourceOfStrength: formikValues.happiness || '', // Adjusted for missing key
    sourceOfHappiness: formikValues.happiness || '',
    futureGoals: formikValues.futureGoals,
    supportOptions: {
      family: formikValues.answers?.[0],
      sportsActivities: formikValues.answers?.[1],
      friends: formikValues.answers?.[2],
      pets: formikValues.answers?.[3],
      religion: formikValues.answers?.[4],
      other: formikValues.answers?.[5],
    },
    musicPlaylist: {
      sadSong: formikValues.sadSong,
      upsetSong: formikValues.upsetSong,
      anxiousSong: formikValues.anxiousSong,
      happySong: formikValues.happySong,
    },
    balloonsText: formikValues.balloonsText,
  };
};
