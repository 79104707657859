import React, { useEffect, useRef, useState } from 'react';
import OnlineGuide from './OnlineGuide';
import FirstForm from './FirstForm';
import Goals from './Goals';
import SimrugGuide from './SimrugGuide';
import SelectForm from './SelectForm';
import InputForm from './InputForm';
import { questionsDetailsOne, questionsDetailsSecond } from './constants/data';
import RadioForm from './RadioForm';
import RadioFormSecond from './RadioFormSecond';
import MultiOption from './MultiOption';
import PolicyForm from './PolicyForm';
import NeinAccept from './NeinAccept';
import JaAccept from './JaAccept';
import PresentationStart from './PresentationStart';
import PresentationEnd from './PresentationEnd';
import CharacterSelect from './CharacterSelect';
import FinalScreen from './FinalScreen';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MultiOptions } from '../../constants/data';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../../redux/Started';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Description from './Description';
import { useTranslation } from 'react-i18next';
import { getInitialValues } from '../common/constant/data';
import OverViewStart from './overViewStart';
import ModuleShow from './moduleShow';
import FinalScreenNav from './finalScreenNav';

const Starting = () => {
  const [searchParams] = useSearchParams();
  const extractInitialValue = () => {
    const renderValue = searchParams.get("render");
    return renderValue ? parseInt(renderValue, 10) : 0;
  };

  const [renderform, setRenderForm] = useState(extractInitialValue);
  const [radioIndex, setRadioIndex] = useState(null);
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo, loading } = useSelector(state => state.initialUserInfo);
  // Define dynamic schema based on renderForm
  const getValidationSchema = () => {
    if (renderform === 5) {
      return Yup.object().shape({
        age: Yup.string().required(t(`levelOneStartError.error_one`)),
        country: Yup.string().required(t(`levelOneStartError.error_two`)),
        gender: Yup.string().required(t(`levelOneStartError.error_three`)),
        email: Yup.string()
          .email(t(`levelOneStartError.error_four`)) // Validates that the input is a valid email
          .required(t(`levelOneStartError.error_five`))
        // phone: Yup.string().required('Telefonnummer ist erforderlich'),
      });
    } else if (renderform === 6) {
      return Yup.object().shape({
        fatherPlaceOfBirth: Yup.string(),
        motherPlaceOfBirth: Yup.string(),
        placeOfBirth: Yup.string()
      });
    } else if (renderform === 7) {
      return Yup.object(
        questionsDetailsOne.reduce((schema, question) => {
          schema[question.id] = Yup.string().required(t(`levelOneStartError.error_ele`));
          return schema;
        }, {})
      );
    } else if (renderform === 8) {
      return Yup.object(
        questionsDetailsSecond.reduce((schema, question) => {
          schema[question.id] = Yup.string().required(t(`levelOneStartError.error_ele`));
          return schema;
        }, {})
      );
    } else if (renderform === 9) {
      return Yup.object({
        MultiOptions: Yup.array().of(Yup.string()),
        // .min(2, 'Bitte wähle mindestens eine Option aus')
        // .test('is-array', 'MultiOptions muss ein Array sein', value =>
        //   Array.isArray(value),
        // ),
        andereText: Yup.string().test(
          t(`levelOneStartError.error_six`),
          t(`levelOneStartError.error_seven`),
          function (value) {
            const { MultiOptions } = this.parent; // Access `MultiOptions` from the parent object
            if (Array.isArray(MultiOptions) && MultiOptions.includes('Other')) {
              return !!value; // Ensure value is non-empty if "Andere" is selected
            }
            return true; // Pass validation otherwise
          }
        )
      });
    } else if (renderform === 10) {
      return Yup.object().shape({
        medication: Yup.string(),

        details: Yup.string().test(
          'details-condition',
          t(`levelOneStartError.error_eight`),
          function (value) {
            const { medication } = this.parent; // Access sibling field `medication`
            if (medication === 'Yes') {
              return !!value && value.length >= 2; // Validate non-empty and length >= 2
            }
            return true; // If `medication` is not 'Yes', skip validation
          }
        ),
        agreement: Yup.boolean()
          .oneOf(
            [true],
            t(`levelOneStartError.error_nine`)
          )
          .required()
      });
    } else if (renderform === 11) {
      return Yup.object().shape({
        termsAndCondition: Yup.boolean()
          .oneOf(
            [true],
            t(`levelOneStartError.error_nine`)
          )
          .required()
      });
    }
    // Add other cases as needed
    return Yup.object(); // Default empty schema
  };

  console.log("radio index", radioIndex)

  const [validationSchema, setValidationSchema] = useState(getValidationSchema);
  const isObjEmpty = (obj) => Object.keys(obj).length === 0;
  function containsJaForQuestions(values, renderform) {
    // Extract relevant keys from questionsDetailsOne
    // const combine_array =  [...questionsDetailsOne, ...questionsDetailsSecond];
    const combine_array =
      renderform === 7 ? questionsDetailsOne : questionsDetailsSecond;

    const relevantValues = combine_array.reduce((acc, question) => {
      acc[question.id] = values[question.id];
      return acc;
    }, {});

    // Check if any value in the relevant subset is 'Ja'
    return Object.values(relevantValues).includes('Ja');
  }
  useEffect(() => {
    setValidationSchema(getValidationSchema()); // Update schema when renderform changes
  }, [renderform]);
  const initialValues = {
    age: '',
    country: '',
    gender: '',
    email: '',
    fatherPlaceOfBirth: '',
    motherPlaceOfBirth: '',
    placeOfBirth: '',
    ...questionsDetailsOne.reduce((values, question) => {
      values[question.id] = ''; // Initialize as empty
      return values;
    }, {}),
    ...questionsDetailsSecond.reduce((values, question) => {
      values[question.id] = ''; // Initialize as empty
      return values;
    }, {}),
    MultiOptions: [], // Store selected options in an array
    andereText: '', // Additional input field for "Andere"
    medication: '', // 'Yes' or 'No'
    details: '', // User input when 'Yes' is selected
    agreement: false, // Checkbox state
    termsAndCondition: false,
  };


  const formik = useFormik({
    initialValues: getInitialValues(initialValues, userInfo),
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      if (renderform === 13) {
        dispatch(setUserInfo(values));

        // setRenderForm(13);
        setRenderForm(renderform + 1);
      } else {
        if (renderform == 5 || renderform == 7 || renderform == 8 || renderform == 10) {
          const notPermission = containsJaForQuestions(values, renderform);
          if (formik?.values?.age < 14 || formik?.values?.age > 18) {
            setRadioIndex(renderform);
            setRenderForm(20);
            return; // Ensure it stops further execution
          }
          if (renderform == 10 && values.medication === 'Yes') {
            setRadioIndex(renderform);
            setRenderForm(20);
            return;
          }
          if (renderform == 8) {
            setRadioIndex(renderform);
            setRenderForm(notPermission == true ? 9 : 10)
            return;
          }
          if (!notPermission) {
            dispatch(setUserInfo(values));
            setRenderForm(renderform + 1);
          } else {
            setRadioIndex(renderform);
            setRenderForm(20);
          }
        } else {
          formik.setTouched({});
          setRenderForm(renderform + 1);
        }
        console.log('data in 13', values.medication);
      }
      console.log("data", formik?.values?.age < 14 && formik?.values?.age > 18)
    }
  });
  const onNextClick = () => {
    formik.handleSubmit();
  };
  const onBackClick = () => {
    if (renderform === 0) {
      navigate('/');
    } else {
      setRenderForm(renderform - 1);
    }
  };
  // console.log('formik Errors:', formik.errors);
  console.log('formik Values:', formik.values);

  return (
    <div>
      {renderform == 0 && (
        <OnlineGuide nextClick={onNextClick} backClick={onBackClick} />
      )}
      {renderform == 1 && (
        <Description backClick={onBackClick} nextClick={onNextClick} />
      )}
      {renderform == 2 && (
        <FirstForm nextClick={onNextClick} backClick={onBackClick} />
      )}
      {renderform == 3 && (
        <Goals nextClick={onNextClick} backClick={onBackClick} />
      )}
      {renderform == 4 && (
        <SimrugGuide nextClick={onNextClick} backClick={onBackClick} />
      )}
      {renderform == 5 && (
        <SelectForm
          nextClick={onNextClick}
          backClick={onBackClick}
          formik={formik}
        />
      )}
      {renderform == 6 && (
        <InputForm
          nextClick={onNextClick}
          backClick={onBackClick}
          formik={formik}
        />
      )}
      {renderform == 7 && (
        <RadioForm
          nextClick={onNextClick}
          backClick={onBackClick}
          formik={formik}
        />
      )}
      {renderform == 8 && (
        <RadioFormSecond
          nextClick={onNextClick}
          backClick={onBackClick}
          formik={formik}
        />
      )}
      {renderform == 9 && (
        <MultiOption
          nextClick={onNextClick}
          backClick={onBackClick}
          formik={formik}
        />
      )}
      {renderform == 10 && (
        <PolicyForm
          nextClick={onNextClick}
          backClick={onBackClick}
          formik={formik}
          setRenderForm={setRenderForm}
          index={radioIndex}
          setIndex={setRadioIndex}
        />
      )}
      {renderform == 20 && (
        <NeinAccept setRenderForm={setRenderForm} index={radioIndex} />
      )}
      {renderform == 11 && (
        <JaAccept
          nextClick={onNextClick}
          backClick={onBackClick}
          formik={formik}
        />
      )}
      {renderform == 12 && (
        <PresentationStart nextClick={onNextClick} backClick={onBackClick} />
      )}
      {renderform == 13 && (
        <PresentationEnd nextClick={onNextClick} backClick={onBackClick} />
      )}
      {renderform == 14 && <CharacterSelect setRenderForm={setRenderForm} />}
      {renderform == 15 && <FinalScreen setRenderForm={setRenderForm} />}
      {renderform == 16 && <OverViewStart nextClick={onNextClick} backClick={onBackClick} />}
      {renderform == 17 && <ModuleShow nextClick={onNextClick} backClick={onBackClick} />}
      {renderform == 18 && <FinalScreenNav backClick={onBackClick} />}
    </div>
  );
};

export default Starting;
