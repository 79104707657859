import React from 'react'
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
const ModuleNav = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <div>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} onClick={() => navigate('/')} >
                {t(`overview_module_start`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} onClick={() => navigate('/level-one')} >
                {t(`overview_module_1`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} onClick={() => navigate('/level-two')}>
                {t(`overview_module_2`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} onClick={() => navigate('/level-three')}>
                {t(`overview_module_3`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} onClick={() => navigate('/level-four')}>
                {t(`overview_module_4`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} onClick={() => navigate('/level-five')}>
                {t(`overview_module_5`)}
            </p>
            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} onClick={() => navigate('/level-six')}>
                {t(`overview_module_6`)}
            </p>
        </div>
    )
}

export default ModuleNav
