
import React from 'react'
import { useTranslation } from "react-i18next";
const Intial = () => {
    const { t } = useTranslation()
    return (
        <div>
            <h1 className='font-bold text-[20px] text-[#fff]'>
                {t(`l6-0-desc`)}
            </h1>
            <h1 className='font-medium text-[16px] text-[#fff] mt-2'>
                {t(`l6-0-para`)}
            </h1>

        </div>
    )
}

export default Intial
