import React, { useState } from "react";
import Tree from "../../../assets/tree.png";
import Star from '../../../assets/icons/star.svg'
import { useTranslation } from "react-i18next";

const TreeWithBranches = ({ formik }) => {
    const { t } = useTranslation()
    const branches = [
        { id: 1, position: { top: "30%", left: "5%" }, label: `${t(`branch`)} 1` },
        { id: 2, position: { top: "15%", left: "15%" }, label: `${t(`branch`)} 2` },
        { id: 3, position: { top: "10%", left: "35%" }, label: `${t(`branch`)} 3` },
        { id: 4, position: { top: "10%", left: "60%" }, label: `${t(`branch`)} 4` },
        { id: 5, position: { top: "25%", left: "90%" }, label: `${t(`branch`)} 5` },
    ];

    const inputPositions = [
        { position: { top: "40%", left: "5%" } },
        { position: { top: "40%", left: "15%" } },
        { position: { top: "40%", left: "35%" } },
        { position: { top: "40%", left: "60%" } },
        { position: { top: "40%", left: "90%" } },
    ];


    const [activeBranch, setActiveBranch] = useState(null);
    const [temporaryInput, setTemporaryInput] = useState("");

    const handleBranchClick = (branchId) => {
        setActiveBranch(branchId);

        // Find the branch object in strengths array and pre-fill its value
        const branchKey = `branch${branchId}`;
        const branch = formik.values.strengths.find((item) => item.branch === branchKey);
        setTemporaryInput(branch?.value || ""); // Pre-fill with existing data or empty if none

        // Set the field as touched when a branch is clicked
        formik.setTouched({
            ...formik.touched,
            strengths: formik.values.strengths.map((item) =>
                item.branch === branchKey ? { ...item, touched: true } : item
            ),
        });
    };

    const handleSaveInput = (branchId) => {
        const branchKey = `branch${branchId}`;

        // Update the value for the specific branch in strengths array
        const updatedStrengths = formik.values.strengths.map((item) =>
            item.branch === branchKey ? { ...item, value: temporaryInput } : item
        );

        formik.setFieldValue("strengths", updatedStrengths); // Save updated array to Formik state
        setActiveBranch(null); // Close input field
    };

    return (
        <div className="relative">
            <div className="relative w-full bg-gray-200">
                {/* Tree Image */}
                <img
                    src={Tree}
                    alt="Tree"
                    className="w-[250px] h-[300px] mx-auto object-cover"
                    onClick={() => setActiveBranch(null)}
                />

                {/* Branch Dots */}
                {branches.map((branch) => (
                    <div
                        key={branch.id}
                        className="absolute w-8 h-8  cursor-pointer"
                        style={{ ...branch.position }}
                        onClick={() => handleBranchClick(branch.id)}
                        title={branch.label} // Tooltip on hover
                    >
                        <img src={Star} className="w-6 h-6" alt="" />

                    </div>
                ))}

                {/* Input Fields */}
                {branches.map((branch, index) =>
                    activeBranch === branch.id ? (
                        <div
                            key={`input-${branch.id}`}
                            className="absolute bg-white p-2 rounded shadow-lg flex flex-col items-end w-60"
                            style={{
                                ...inputPositions[index]?.position, // Dynamically set input positions
                                transform: "translate(-50%, -50%)", // Center the input box
                            }}
                        >
                            <input
                                type="text"
                                placeholder={branch.label}
                                value={temporaryInput}
                                onChange={(e) => setTemporaryInput(e.target.value)}
                                className="border px-2 py-1 rounded w-full mb-2"
                            />
                            {/* Error Message */}
                            {formik.touched.branches?.[branch.id - 1] &&
                                formik.errors.branches?.[branch.id - 1] && (
                                    <p className="text-red-500 text-xs mb-1 w-full text-left">
                                        {formik.errors.branches[branch.id - 1]}
                                    </p>
                                )}

                            {/* Save Button */}
                            <button
                                onClick={() => handleSaveInput(branch.id)}
                                className="text-[#2C2954] text-xs px-3 py-1 border border-[#2C2954] rounded flex items-center gap-1"
                            >
                                <span>✔</span> {t(`save`)}
                            </button>
                        </div>
                    ) : null
                )}
            </div>
        </div>
    );
};

export default TreeWithBranches;
