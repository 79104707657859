import React from 'react';
import { useFormik } from 'formik';
import mainBG from '../../../assets/EinführungMainBG.png';
import ellipseBG from '../../../assets/EllipseBG.png';
import { useTranslation } from "react-i18next";
const PolicyForm = ({ nextClick, backClick, formik, setRenderForm, index, setIndex }) => {
    const isSmallScreen = window.innerHeight <= 600;
    const { t } = useTranslation()
    console.log("const index", index)
    return (
        <div
            style={{ background: `url(${mainBG})` }}
            className="h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between"
        >
            <div></div>
            <div
                style={{ background: `url(${ellipseBG})` }}
                className="min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative"
            >
                <div className="relative h-full mx-auto w-full max-w-[36rem] text-center pb-4">
                    <div className='h-full'>
                        <div className='flex flex-col justify-between h-full pt-24 items-center' >
                            <div className="max-w-[80%] mx-auto">
                                <div className="flex flex-col items-start">
                                    <p className="font-semibold text-xl text-white text-center">

                                        {t(`s-policy-title`)}
                                    </p>

                                    <div className="flex items-center gap-2">
                                        <input
                                            type="radio"
                                            className="custom-radio my-3"
                                            id="ja"
                                            name="medication"
                                            value="Yes"
                                            checked={formik.values.medication === 'Yes'}
                                            onChange={formik.handleChange}
                                        />
                                        <label htmlFor="ja" className="font-normal text-base text-white">
                                            {t(`yes`)}
                                        </label>
                                        {formik.values.medication === 'Yes' && (
                                            <input
                                                type="text"
                                                name="details"
                                                placeholder={t(`s-policy-place`)}
                                                className="simurg_input !w-auto"
                                                value={formik.values.details}
                                                onChange={formik.handleChange}
                                            />
                                        )}
                                    </div>

                                    {formik.errors.details && formik.values.medication === 'Yes' && (
                                        <div className="text-red-500 text-sm">{formik.errors.details}</div>
                                    )}

                                    <div className="flex items-center gap-2">
                                        <input
                                            type="radio"
                                            className="custom-radio my-3"
                                            id="nein"
                                            name="medication"
                                            value="No"
                                            checked={formik.values.medication === 'No'}
                                            onChange={formik.handleChange}
                                        />
                                        <label htmlFor="nein" className="font-normal text-base text-white">
                                            {t(`no`)}
                                        </label>
                                    </div>

                                    {formik.errors.medication && (
                                        <div className="text-red-500 text-sm">{formik.errors.medication}</div>
                                    )}


                                    <div className="flex items-start gap-2 py-2">
                                        <input
                                            type="checkbox"
                                            id="policy"
                                            name="agreement"
                                            checked={formik.values.agreement}
                                            onChange={formik.handleChange}
                                            className="radio-circle !w-full !max-w-[20px]"
                                        />
                                        <label
                                            htmlFor="policy"
                                            className="font-semibold text-base text-white text-left"
                                        >
                                            {t(`s-policy-des`)}
                                            <br />
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="underline"
                                                href={t(`drive_link_3`)}
                                            >
                                                {t(`s-policy-link`)}

                                            </a>                                           {t(`s-policy-des2`)}
                                        </label>
                                    </div>

                                    {formik.errors.agreement && (
                                        <div className="text-red-500 text-sm">{formik.errors.agreement}</div>
                                    )}
                                </div>

                            </div>

                            <div className={`${isSmallScreen ? 'pt-2' : 'pt-6'} flex gap-6 w-full`}>
                                <button className='w-full button_primary' onClick={() => { setRenderForm(index ? index == 10 ? 8 : index : 9); setIndex(null) }} >{t(`s-2-btn1`)}</button>
                                <button className='w-full button_secodry' onClick={nextClick} >{t(`s-2-btn2`)}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PolicyForm;
