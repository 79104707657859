import React from 'react'
import Image4 from '../../../assets/images/image 4.png'
import Image5 from '../../../assets/images/image 5.png'
import Image6 from '../../../assets/images/image 6.svg'
import { useTranslation } from "react-i18next";

const Description = ({ nextClick, backClick }) => {
    const { t } = useTranslation()
    return (
        <div className='bg-white w-full min-h-screen p-4 flex items-center justify-center' >
            <div className="max-w-[1000px] mx-auto ">
                <p className='text-center mb-12' >
                    {t(`des_title`)}
                </p>

                <div className="grid grid-cols-3 gap-5">
                    <div className="text-center">
                        <p className='text-black text-[12px] text-left max-w-[180px] mx-auto' >{t(`des_heading`)} </p>
                        <img src={Image4} alt="" className='mx-auto' />
                    </div>
                    <div className="">
                        <p className='text-black text-[12px] mt-16 ' >
                            <span className='italic font-bold' >
                                {t(`des_secone_title`)}
                                <br />
                                {t(`des_secone_des1`)}

                                <br />
                                {t(`des_secone_des2`)}

                                <br />
                                {t(`des_secone_des3`)}

                            </span>
                            <br />
                            {t(`des_secone_des4`)}

                        </p>
                    </div>
                    <div className="">
                        <p className='text-black text-[12px] mt-16 ' >
                            {t(`des_secone_des5`)}
                            <br />
                            {t(`des_secone_des6`)}
                            <br />
                            {t(`des_secone_des7`)}
                            <br />
                            {t(`des_secone_des8`)}
                            <br />
                            {t(`des_secone_des9`)}
                            <br />
                            {t(`des_secone_des10`)}

                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-5">
                    <div className="text-center">
                        <img src={Image5} alt="" className='mx-auto mt-20' />
                    </div>
                    <div className="col-span-2">
                        <div className="grid grid-cols-2 gap-5">
                            <p className='text-black text-[12px] mt-20 ' >
                                <span className="font-bold">
                                    {t(`des_sectwo_title`)}

                                </span>
                                <br />
                                <span className='italic font-bold' >
                                    {t(`des_sectwo_des1`)}
                                    <br />
                                    {t(`des_sectwo_des2`)}
                                    <br />
                                    {t(`des_sectwo_des3`)}

                                </span>
                                <br />
                                {t(`des_sectwo_des4`)}

                                <br />
                                {t(`des_sectwo_des5`)}
                            </p>
                            <div className="">
                                <p className='text-black text-[12px] mb-5' >
                                    {t(`des_sectwo_des6`)}

                                    <br />
                                    {t(`des_sectwo_des7`)}

                                    <br />
                                    {t(`des_sectwo_des8`)}

                                    <br />
                                    {t(`des_sectwo_des9`)}

                                    <br />
                                    {t(`des_sectwo_des10`)}

                                </p>
                                <p className='text-black text-[12px] mb-5' >
                                    {t(`des_sectwo_des11`)}

                                    <br />
                                    {t(`des_sectwo_des12`)}

                                    <br />
                                    {t(`des_sectwo_des13`)}

                                    <br />
                                    {t(`des_sectwo_des14`)}

                                    <br />
                                    {t(`des_sectwo_des15`)}

                                    <br />
                                    {t(`des_sectwo_des16`)}

                                    <br />
                                    {t(`des_sectwo_des17`)}

                                </p>
                            </div>
                        </div>
                        <img src={Image6} className='w-[70%] mt-2' alt="" />
                    </div>

                </div>
                <div className='pt-8 flex gap-6 max-w-[36rem] mx-auto'>
                    <button className='w-full button_primary !text-black' onClick={backClick} > {t(`s-2-btn1`)} </button>
                    <button className='w-full button_secodry' onClick={nextClick} >{t(`s-2-btn2`)}</button>
                </div>

            </div>


        </div>
    )
}

export default Description
