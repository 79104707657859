import React from 'react'
import mainBG from '../../../assets/EinführungMainBG.png'
import ellipseBG from '../../../assets/EllipseBG.png'
import { BookIcon, ForumIcon, VolunteerIcon } from '../../../utils/icons'
import { useTranslation } from "react-i18next";
const Goals = ({ nextClick, backClick }) => {
    const isSmallScreen = window.innerHeight <= 600;
    const { t } = useTranslation()
    return (
        <>
            <div style={{ background: `url(${mainBG})` }} className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'>
                <div className='font-bold text-base text-[#0F32C0E5] self-center italic py-8'>{t(`s-4-titlemain`)}</div>
                <div style={{ background: `url(${ellipseBG})` }} className={`min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative`}>
                    <div className={`relative ${isSmallScreen ? 'pt-12' : 'pt-20'}  pb-4`}>
                        <div className="w-full max-w-xs flex flex-col justify-between mx-auto h-full justify-self-center text-center">
                            <div>

                                <div className="flex items-center text-center mb-8 justify-between">
                                    <VolunteerIcon />
                                    <p className={`text-white font-normal ${isSmallScreen ? 'text-sm' : 'text-base'} ${isSmallScreen ? 'max-w-64' : 'max-w-56'}`}>
                                        {t(`s-4-title1`)}
                                    </p>
                                </div>

                                <div className="flex items-center text-center mb-8 justify-between">
                                    <BookIcon />
                                    <p className={`text-white font-normal ${isSmallScreen ? 'text-sm' : 'text-base'} ${isSmallScreen ? 'max-w-64' : 'max-w-56'}`}>
                                        {t(`s-4-title2`)}
                                    </p>
                                </div>

                                <div className="flex items-center text-center mb-8 justify-between">
                                    <ForumIcon />
                                    <p className={`text-white font-normal ${isSmallScreen ? 'text-sm' : 'text-base'} ${isSmallScreen ? 'max-w-64' : 'max-w-56'}`}>
                                        {t(`s-4-title3`)}
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className='pt-8 flex gap-6 max-w-[36rem] mx-auto'>
                            <button className='w-full button_primary' onClick={backClick} >{t(`s-2-btn1`)}</button>
                            <button className='w-full button_secodry' onClick={nextClick} >{t(`s-2-btn2`)}</button>
                        </div>

                        {/* <div className={`${isSmallScreen ? 'pt-2' : 'pt-4'} flex gap-2`}>
                            <button className='w-full button_main !border-transparent' onClick={nextClick} >Lass uns anfangen</button>
                        </div> */}

                    </div>

                </div>
            </div>
        </>
    )
}

export default Goals