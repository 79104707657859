import React from 'react'
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
const OverviewMail = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const currentLanguage = useSelector((state) => state.language);

    return (
        <div>
            <p className={`text-xl font-bold text-white`}>
                {(currentLanguage?.language != 'tr' || location.pathname != '/level-six') ? t(`introduction_des`) : ''}    <a href={`mailto:${t(`introduction_email`)}`} > {t(`introduction_email`)} </a> {t(`introduction_des_2`)}
            </p>
        </div>
    )
}

export default OverviewMail
