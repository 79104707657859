import React from 'react';
import { useFormik } from 'formik';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import mainBG from '../../../assets/EinführungMainBG.png';
import ellipseBG from '../../../assets/EllipseBG.png';
import { country, gender } from '../constants/data';
import { useTranslation } from "react-i18next";
const validate = values => {
    const errors = {};
    if (!values.country) {
        errors.country = 'Bundesland is required';
    }
    if (!values.gender) {
        errors.gender = 'Geschlecht is required';
    }
    if (!values.age) {
        errors.age = 'Bitte gib dein Alter an.';
    }
    if (!values.phone) {
        errors.phone = 'Bitte gib deine Telefonnummer an.';
    } else if (!/^\+?\d{10,15}$/.test(values.phone)) {
        errors.phone = 'Bitte gib eine gültige Telefonnummer an.';
    }
    return errors;
};

const SelectForm = ({ nextClick, backClick, formik }) => {
    const { t } = useTranslation()

    return (
        <div
            style={{ background: `url(${mainBG})` }}
            className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'
        >
            <div></div>
            <div
                style={{ background: `url(${ellipseBG})` }}
                className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'
            >
                <div className='relative h-full mx-auto w-full max-w-[36rem] text-center pb-4'>
                    <div className='h-full'>
                        <div
                            className='flex flex-col justify-between h-full pt-20'

                        >
                            <div className='max-w-[80%] mx-auto'>
                                <h1 className='text-center text-white font-semibold text-lg mb-6'>
                                    {t(`s-6-title`)}
                                </h1>

                                <div className='mb-6'>
                                    <div className='relative'>
                                        <select
                                            name="country"
                                            value={formik.values.country}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className='simurg_input appearance-none pr-10'
                                        >
                                            <option value="" disabled>{t('country.burgenland')}</option>
                                            {country?.map((value, index) => (
                                                <option value={value.value} key={index}>{t(`country.${value.label}`)}</option>
                                            ))}
                                        </select>
                                        <span className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                                            <svg
                                                className="w-4 h-4 text-gray-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M19 9l-7 7-7-7"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                    {formik.touched.country && formik.errors.country && (
                                        <div className="text-red-500 text-sm mt-1 text-left">{formik.errors.country}</div>
                                    )}
                                </div>

                                <div className='mb-6'>
                                    <div className='relative'>
                                        <select
                                            name="gender"
                                            value={formik.values.gender}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className='simurg_input appearance-none pr-10'
                                        >
                                            <option value="" disabled>{t(`gender.gender`)}</option>
                                            {gender?.map((value, index) => (
                                                <option value={value.value} key={index}>{t(`gender.${value.label}`)}</option>
                                            ))}
                                        </select>
                                        <span className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                                            <svg
                                                className="w-4 h-4 text-gray-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M19 9l-7 7-7-7"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                    {formik.touched.gender && formik.errors.gender && (
                                        <div className="text-red-500 text-sm mt-1 text-left">{formik.errors.gender}</div>
                                    )}
                                </div>

                                <div className='mb-6'>
                                    <div className='relative'>
                                        <input name="age" type="number" placeholder={t(`age`)} className='simurg_input appearance-none pr-10' value={formik.values.age}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />
                                        {/* <select
                                            name="age"
                                            value={formik.values.age}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className='simurg_input appearance-none pr-10'
                                        >
                                            <option value="" disabled>{t(`age`)}</option>
                                            {[14, 15, 16, 17, 18].map(age => (
                                                <option key={age} value={age}>{age}</option>
                                            ))}
                                        </select>
                                        <span className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                                            <svg
                                                className="w-4 h-4 text-gray-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M19 9l-7 7-7-7"
                                                />
                                            </svg>
                                        </span> */}
                                    </div>
                                    {formik.touched.age && formik.errors.age && (
                                        <div className="text-red-500 text-sm mt-1 text-left">{formik.errors.age}</div>
                                    )}
                                </div>

                                <div className='mb-6'>
                                    {/* <PhoneInput
                                        country={'de'}
                                        value={formik.values.phone}
                                        onChange={(phone) => formik.setFieldValue('phone', phone)}
                                    />
                                    {formik.touched.phone && formik.errors.phone && (
                                        <div className="text-red-500 text-sm mt-1 text-left">{formik.errors.phone}</div>
                                    )} */}
                                    <input
                                        type='email'
                                        name='email'
                                        id='email'
                                        placeholder={t(`email`)}
                                        className={'simurg_input'}
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.email && formik.errors.email && (
                                        <div className="text-red-500 text-sm mt-1 text-left">{formik.errors.email}</div>
                                    )}
                                </div>
                            </div>

                            <div className='flex gap-6'>
                                <button className='w-full button_primary' onClick={backClick} >{t(`s-2-btn1`)}</button>
                                <button className='w-full button_secodry' onClick={nextClick} >{t(`s-2-btn2`)}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectForm;
