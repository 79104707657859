import React from 'react';
import mainBG from '../../../assets/EinführungMainBG.png';
import ellipseBG from '../../../assets/EllipseBG.png';
import { useTranslation } from 'react-i18next';
const NeinAccept = ({ setRenderForm, index }) => {
  const { t } = useTranslation()
  return (
    <>
      <div
        style={{ background: `url(${mainBG})` }}
        className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'
      >
        <div></div>
        <div
          style={{ background: `url(${ellipseBG})` }}
          className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'
        >
          <div className='relative pt-20 w-full max-w-[36rem] mx-auto flex flex-col justify-between h-full justify-self-center text-center pb-4'>
            <div>
              <h1 className='font-semibold text-white text-base'>{t(`s-17-paraone`)}</h1>
              <h1 className='font-semibold text-white text-base mt-4'>{t(`s-17-paratwo`)}</h1>
              <h1 className='font-semibold text-white text-base mt-4'>
                {t(`s-17-parathree`)}
                <br />
                <a
                  target='_blank'
                  className='underline'
                  href={t(`drive_link_1`)}
                >{t(`s-17-parathree-link`)}</a>
              </h1>
              <h1 className='font-semibold text-white text-base mt-4'>
                {t(`s-17-parafour`)}
                <br />
                <a
                  target='_blank'
                  className='underline'
                  href={t(`drive_link_2`)}
                >{t(`s-17-parafour-link`)}</a>
              </h1>
            </div>

            <div className='pt-10 flex gap-6'>
              <button
                type='button'
                className='w-full button_primary'
                onClick={() => setRenderForm(index)}
              >
                {t(`s-2-btn1`)}
              </button>
              {/* <button type='submit' className='w-full button_secodry' disabled>
                {t(`s-2-btn2`)}
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NeinAccept;
