import React from 'react'
import mainBG from '../../../assets/EinführungMainBG.png'
import ellipseBG from '../../../assets/EllipseBG.png'
import techVideo from '../../../assets/tech.mov'
import { useTranslation } from "react-i18next";

const PresentationEnd = ({ nextClick, backClick }) => {
    const isSmallScreen = window.innerHeight <= 600;
    const { t } = useTranslation()
    return (
        <>
            <div style={{ background: `url(${mainBG})` }} className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'>
                <div></div>
                <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                    <div className={`relative ${isSmallScreen ? 'pt-12' : 'pt-20'} w-full ${isSmallScreen ? 'max-w-[38rem]' : 'max-w-[36rem]'} flex mx-auto flex-col justify-between h-full justify-self-center text-center pb-4`}>
                        <div>
                            <h1 className='font-semibold text-white text-xl'>{t(`s-14-title`)}</h1>
                            <h1 className='font-semibold text-white text-sm mt-4'>{t(`s-14-desc`)}</h1>

                            <div className='mt-8'>
                                <video
                                    className='w-full h-auto max-w-[300px] mx-auto'
                                    controls
                                >
                                    <source src={t(`video_link_2`)} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>

                        </div>

                        <div className={`${isSmallScreen ? 'pt-2' : 'pt-10'} flex gap-6`}>
                            <button className='w-full button_primary' onClick={backClick} >{t(`s-2-btn1`)}</button>
                            <button className='w-full button_secodry' onClick={nextClick} >{t(`s-2-btn2`)}</button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default PresentationEnd