import React, { useEffect, useRef, useState } from 'react';
import ellipseBG from '../../assets/EllipseBG.png'
import Trophy from '../../assets/trophy.png'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import ScalarForm, { sliderConfig } from '../common/forms/ScalarForm';
import LevelRadioSelection from '../common/forms/LevelRadioSelection';
import LevelRadioSelectionlastSeven from '../common/forms/LevelRadioSelectionlastSeven';
import { firstSevenQuestions, getInitialValues, lastSevenQuestions } from '../common/constant/data';
import { getValidationSchema } from './constant';
import { firstSevenResources, secondSevenResources, thirdSevenResources, List } from '../common/constant/data';
import { useSelector, useDispatch } from 'react-redux';
import techVideo from '../../assets/tech.mov'
import CommonRadioForm from './forms/CommonRadioForm'
import Intial from './forms/intial';
import ResourcesFormOne from './forms/ResourcesFormOne';
import { setUserLevelFourInfo } from '../../redux/Started';
import { handleAddedPersonalInfoAction } from '../../redux/Started/apisActions';
import Spiner from '../common/constant/spiner';
import { useTranslation } from "react-i18next";
import OverviewMail from '../common/forms/overviewMail';
import ModuleNav from '../common/forms/moduleNav';

const LevelFour = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const scrollViewRef = useRef(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const extractInitialValue = () => {
        const renderValue = searchParams.get("render");
        return renderValue ? parseInt(renderValue, 10) : 0;
    };

    const [renderForm, setRenderForm] = useState(extractInitialValue);
    const [validationSchema, setValidationSchema] = useState(
        getValidationSchema(renderForm, t),
    );


    useEffect(() => {
        setValidationSchema(getValidationSchema(renderForm - 1, t)); // Update schema when renderForm changes
    }, [renderForm]);

    const { levelFourForm, loading } = useSelector(state => state.initialUserInfo);
    const { userID } = useSelector(state => state.user);
    const levelFourInitialValues = {
        ...sliderConfig.reduce(
            (values, slider) => ({
                ...values,
                [slider.name]: 0,
            }),
            {},
        ),
        ...firstSevenQuestions.reduce(
            (values, question) => ({
                ...values,
                [question.keyName]: '', // Default value for each question
            }),
            {},
        ),
        ...lastSevenQuestions.reduce(
            (values, question) => ({
                ...values,
                [question.keyName]: '', // Default value for each question
            }),
            {},
        ),
        ...firstSevenResources.reduce(
            (values, question) => ({
                ...values,
                [question.keyName]: '', // Default value for each question
            }),
            {},
        ),
        ...secondSevenResources.reduce(
            (values, question) => ({
                ...values,
                [question.keyName]: '', // Default value for each question
            }),
            {},
        ),
        ...thirdSevenResources.reduce(
            (values, question) => ({
                ...values,
                [question.keyName]: '', // Default value for each question
            }),
            {},
        ),
        trophyText: '',
    }
    const formik = useFormik({
        initialValues: getInitialValues(levelFourInitialValues, levelFourForm),
        validationSchema,
        onSubmit: values => {
            const data = {
                userId: userID || '',
                LevelFour: JSON.stringify(values),
            };
            if (renderForm === 12) {
                dispatch(setUserLevelFourInfo(values));
                dispatch(
                    handleAddedPersonalInfoAction({
                        data,
                        // callback: () => navigate('/level-five'),
                        callback: res => setRenderForm(renderForm + 1),
                    }),
                );
                // navigate('/level-five');
            } else {
                if (renderForm == 15) {
                    navigate('/level-five')
                }
                formik.setTouched({});
                setRenderForm(renderForm + 1);
            }
            // console.log('Form Values:', values);
        },
    });



    return (
        <>
            <div className='self-center pt-4 text-center '>
                {renderForm === 2 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >
                            {t(`l1-2-title`)}
                        </h1>
                        <h1 className='text-[14px] text-mainBlue font-bold italic ' >
                            {t(`l1-2-title-one`)}

                        </h1>
                        <h1 className='text-[14px] text-mainBlue font-semibold' >    {t(`l1-2-title-two`)}</h1>
                    </>
                )}
                {renderForm === 0 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >Level 4
                        </h1>
                        <h1 className='text-[16px] text-mainBlue font-bold italic ' >
                            {t(`l4-0-title`)} </h1>
                        <h1 className='text-[16px] text-mainBlue font-semibold' > {t(`l4-0-title_one`)}</h1>
                    </>
                )}
                {renderForm === 4 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l4-5-title`)} </h1>
                    </>
                )}
                {renderForm === 5 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l4-6-title`)} </h1>
                    </>
                )}
                {renderForm === 6 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l4-6-title`)}  </h1>
                    </>
                )}
                {renderForm === 7 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l4-6-title`)}   </h1>
                    </>
                )}
                {renderForm === 8 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >  {t(`l4-9-title`)}   </h1>
                    </>
                )}
                {renderForm === 9 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >  {t(`l4-10-title`)}   </h1>
                    </>
                )}
                {renderForm === 10 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >  {t(`l4-11-title`)}   </h1>
                    </>
                )}
                {renderForm === 11 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l4-12-title`)} <br /> {t(`l4-12-title_two`)}   </h1>
                    </>
                )}
                {renderForm === 12 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l4-13-title`)}   </h1>
                    </>
                )}
                {renderForm === 13 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >{t(`overview_title_super`)} <br /> {t(`introduction_l4_title`)}   </h1>
                    </>
                )}
                {renderForm === 14 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`overview_title`)}   </h1>
                    </>
                )}
                {renderForm === 15 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`overview_title`)}  <br /> Level 4  </h1>
                    </>
                )}

            </div>
            <div style={{ background: `url(${ellipseBG})` }} className='min-h-[85vh] !bg-cover !bg-no-repeat !bg-top relative'>
                <div className='relative pt-20 w-full max-w-[56rem] mx-auto flex flex-col justify-between h-full justify-self-center text-center py-2'>
                    {/* <Intial /> */}
                    <div className="max-w-[33rem] mx-auto">
                        {renderForm === 0 && <Intial />}
                        {renderForm === 1 && <ScalarForm formik={formik} />}
                    </div>
                    <div>
                        <div className="max-w-[36rem] mx-auto">
                            {renderForm === 2 && <LevelRadioSelection formik={formik} />}
                            {renderForm === 3 && <LevelRadioSelectionlastSeven formik={formik} />}

                            {renderForm === 4 && (
                                <div className="flex flex-col gap-5 items-start md:px-5 px-2 ">
                                    <p className="text-[16px] font-bold italic text-white">
                                        {t(`l4-5-desc`)}
                                    </p>
                                    <ul>
                                        {List.map((item, index) => (
                                            <li key={index} className="flex text-left gap-2">
                                                <span className="text-[16px] font-bold text-white mt-2">{index + 1}.</span>
                                                <p className="text-[16px] font-bold text-white mt-2">
                                                    {t(`list.${item?.keyName}`)}  <a
                                                        target='_blank'
                                                        className="underline  cursor-pointer"
                                                        href={t(`${item.url}`)}
                                                    >
                                                        {t(`list.questionsLink`)}
                                                    </a>
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {renderForm === 8 && (
                                <div className="">
                                    <p className="text-[13px] text-white italic font-bold">
                                        {t(`l4-9-desc`)}
                                        {/* <a
                                            target='_blank'
                                            className="underline cursor-pointer"
                                            href={t(`drive_link_15`)}
                                        >
                                            {t(`l4-9-desc_link`)}
                                        </a> */}
                                        {t(`l4-9-desc_two`)}
                                    </p>
                                    <div className='mt-2 max-w-[270px] mx-auto'>
                                        <video
                                            className='w-full h-auto '

                                            controls
                                        >
                                            <source src={t(`video_link_10`)} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            )}

                            {renderForm === 9 && (
                                <div className="flex flex-col gap-3 items-center">
                                    <p className="text-[13px] text-white italic font-bold">
                                        {t(`l4-10-heading1`)}
                                    </p>
                                    <div className='mt-2 max-w-[200px] mx-auto'>
                                        <video
                                            className='w-full h-auto '

                                            controls
                                        >
                                            <source src={t(`video_link_11`)} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <p className="text-[13px] text-white italic font-bold">
                                        {t(`l4-10-heading2`)}
                                    </p>
                                    <div className='mt-2 max-w-[200px] mx-auto'>
                                        <video
                                            className='w-full h-auto '

                                            controls
                                        >
                                            <source src={t(`video_link_12`)} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            )}

                            {renderForm === 10 && (
                                <div className="flex flex-col gap-3">
                                    <div>
                                        <p className="text-[13px] text-white italic font-bold">
                                            {t(`l4-11-para1`)}

                                        </p>
                                        <p className="text-[13px] text-white italic font-bold">
                                            {t(`l4-11-para2`)}
                                        </p>
                                        <p className="text-[13px] text-white italic font-bold">
                                            {t(`l4-11-para3`)}
                                        </p>
                                    </div>
                                    <div className='mt-2 max-w-[270px] mx-auto'>
                                        <video
                                            className='w-full h-auto '

                                            controls
                                        >
                                            <source src={t(`video_link_13`)} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            )}

                            {renderForm === 11 && (
                                <div className="flex flex-col gap-3">
                                    <p className="text-[13px] text-white italic font-bold">
                                        {t(`l4-12-desc`)}
                                    </p>
                                    <a
                                        target='_blank'
                                        className="text-[13px] text-white italic font-bold underline cursor-pointer"
                                        href={t(`drive_link_16`)}
                                    >
                                        {t(`l4-12-para`)}
                                    </a>
                                    <div className='mt-2 max-w-[270px] mx-auto'>
                                        <video
                                            className='w-full h-auto '

                                            controls
                                        >
                                            <source src={t(`video_link_14`)} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            )}

                            {renderForm === 12 && (
                                <div className="flex flex-col gap-3 -mt-10">
                                    <p className="text-xl font-medium text-white text-center">
                                        {t(`l4-13-heading`)}
                                    </p>
                                    <p className="text-lg font-medium text-white text-center italic">
                                        {t(`l4-13-desc`)}
                                    </p>
                                    <div className="">
                                        <img
                                            src={Trophy}
                                            alt="Trophy"
                                            className="animate-slideInUp mx-auto w-[200px]"
                                        />
                                        <div className="mt-2 w-[225px] px-3 mx-auto " >
                                            <input
                                                type="text"
                                                className=" simurg_input h-10 border-b border-black text-black outline-none"
                                                placeholder=""

                                                value={formik.values.trophyText}
                                                onChange={(e) => formik.setFieldValue('trophyText', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <p className="text-[13px] text-white italic font-bold"> {t(`l4-13-para1`)}</p>
                                        <p className="text-[13px] text-white italic font-bold">
                                            {t(`l4-13-para2`)}
                                        </p>
                                        <p className="text-[13px] text-white italic font-bold">
                                            {t(`l4-13-para3`)}
                                        </p>
                                    </div>
                                </div>
                            )}

                            {renderForm === 13 && <OverviewMail />}
                            {renderForm === 14 && <ModuleNav />}
                            {renderForm === 15 && <div className='text-left' >

                                <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`}  >
                                    {t(`overview_module_start_four`)}
                                </p>
                                <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                                    {t(`overview_module_1_four`)}
                                </p>
                                <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                                    {t(`overview_module_2_four`)}
                                </p>
                                <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                                    {t(`overview_module_3_four`)}
                                </p>
                                <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                                    {t(`overview_module_4_four`)}
                                </p>
                                <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                                    {t(`overview_module_5_four`)}
                                </p>
                                <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                                    {t(`overview_module_6_four`)}
                                </p>
                                <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                                    {t(`overview_module_7_four`)}
                                </p>
                                <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                                    {t(`overview_module_8_four`)}
                                </p>
                                <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                                    {t(`overview_module_9_four`)}
                                </p>
                            </div>}
                        </div>


                        <div className="px-5">
                            {(renderForm === 5 || renderForm === 6 || renderForm === 7) && (
                                <ResourcesFormOne formik={formik} renderForm={renderForm} />
                            )}
                        </div>



                    </div>
                    <div className="max-w-[36rem] mx-auto mb-2">
                        <div className='pt-4 flex gap-2 w-[300px] md:w-[36rem]'>
                            <button className='w-full button_primary' onClick={() => {
                                if (renderForm === 0) {
                                    navigate('/level-three?render=11');
                                } else {
                                    setRenderForm(renderForm - 1);
                                }
                                // navigation.goBack();
                            }} >{t(`s-2-btn1`)}</button>
                            <button className='w-full button_secodry relative' onClick={() => {
                                formik.handleSubmit();
                            }}
                                disabled={loading}
                            >

                                {loading && (
                                    <Spiner />
                                )}
                                <span className={loading ? "opacity-0" : ""}>{t(`s-2-btn2`)}</span>

                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LevelFour
