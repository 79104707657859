
import React from 'react'
import { useTranslation } from "react-i18next";
const Intial = () => {
    const { t } = useTranslation()
    return (
        <div>
            <div className='max-w-[450px] mx-auto' >
                <h1 className={`font-semibold text-white  text-lg`}> {t(`l1-0-desc1`)} </h1>
                <p className={`font-bold italic  text-base mt-4 text-white`}> {t(`l1-0-desc2`)} </p>
                <p className={`font-bold italic  text-base mt-4 text-white`}> {t(`l1-0-desc3`)} </p>
            </div>

        </div>
    )
}

export default Intial
