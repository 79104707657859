import * as Yup from 'yup';
import { sliderConfig } from '../../common/forms/ScalarForm';
import { firstSevenQuestions } from '../../common/constant/data';
import { lastSevenQuestions } from '../../common/constant/data';
export const getValidationSchema = (renderForm, t) => {
    if (renderForm === 0) {
        return Yup.object(
            sliderConfig.reduce((schema, slider) => {
                schema[slider.name] = Yup.number()
                    .required(t(`levelOneError.error_one`))
                    .min(slider.minValue, `${t(`levelOneError.error_two`)} ${slider.minValue}.`)
                    .max(slider.maxValue, `${t(`levelOneError.error_four`)} ${slider.maxValue}.`);
                return schema;
            }, {}),
        );
    } else if (renderForm === 1) {
        return Yup.object(
            firstSevenQuestions.reduce((schema, question) => {
                schema[question.keyName] = Yup.string().required(
                    t(`levelOneError.error_three`),
                );
                return schema;
            }, {}),
        );
    } else if (renderForm === 2) {
        return Yup.object(
            lastSevenQuestions.reduce((schema, question) => {
                schema[question.keyName] = Yup.string().required(
                    t(`levelOneError.error_three`),
                );
                return schema;
            }, {}),
        );
    }
    // Add other cases as needed
    return Yup.object(); // Default empty schema
};
