import React from 'react'
import { useTranslation } from "react-i18next";
const Intial = () => {
    const { t } = useTranslation()
    const isSmallScreen = window.innerHeight <= 600;

    return (
        <div className='max-w-[480px] mx-auto' >
            <h1 className={`font-bold text-[17px] mb-3 text-[#fff]`}>
                {t(`l2-0-desc1`)}
            </h1>
            <h1 className={`font-bold text-[17px] mb-3 text-[#fff] mt-2`}>
                {t(`l2-0-desc2`)}
            </h1>
            <h1 className={`font-bold text-[17px] mb-3 text-[#fff] mt-2`}>{t(`l2-0-desc3`)}</h1>

        </div>
    )
}

export default Intial
