import React from 'react';
import CommonRadioForm from './CommonRadioForm';
import { firstSevenResources, secondSevenResources, thirdSevenResources } from '../../common/constant/data';
import { useTranslation } from "react-i18next";
const ResourcesFormOne = ({ formik, renderForm }) => {
  const { t } = useTranslation()
  return (
    <div className="px-4 pt-4">
      {renderForm === 5 && (
        <div className='-mt-4' >
          <p className="text-[16px] font-bold text-gray-100 pb-1 max-w-[500px] mx-auto">

            {t(`l4-6-desc1`)}  <br />
            {t(`l4-6-desc2`)}
          </p>
          <CommonRadioForm list={firstSevenResources} formik={formik} />
        </div>
      )}
      {renderForm === 6 && <CommonRadioForm list={secondSevenResources} formik={formik} />}
      {renderForm === 7 && <CommonRadioForm list={thirdSevenResources} formik={formik} />}
    </div>
  );
};

export default ResourcesFormOne;
