import * as Yup from 'yup';
import { sliderConfig } from '../../common/forms/ScalarForm';
import { firstSevenQuestions, lastSevenQuestions } from '../../common/constant/data';



export const getValidationSchema = (renderForm, t) => {
  if (renderForm === 0) {
    return Yup.object(
      sliderConfig.reduce((schema, slider) => {
        schema[slider.name] = Yup.number()
          .required(t(`levelOneError.error_one`))
          .min(slider.minValue, `${t(`levelOneError.error_two`)} ${slider.minValue}.`)
          .max(slider.maxValue, `${t(`levelOneError.error_four`)} ${slider.maxValue}.`);
        return schema;
      }, {}),
    );
  } else if (renderForm === 1) {
    return Yup.object(
      firstSevenQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          t(`levelOneError.error_three`),
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 2) {
    return Yup.object(
      lastSevenQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          t(`levelOneError.error_three`),
        );
        return schema;
      }, {}),
    );
  }
  else if (renderForm === 4) {
    return Yup.object().shape({
      sociogram: Yup.array()
        .of(Yup.string().notRequired())
        .test(
          'at-least-one',
          t(`levelOneError.error_five`),
          (array) => array && array.some((value) => value)
        ),
    });
  }
  else if (renderForm === 5) {
    return Yup.object().shape({
      sentences: Yup.array().min(1, t(`levelOneError.error_five`)), // Validate at least one sentence is selected
      otherSentencesReason: Yup.string().test(
        'is-other-reason-required',
        t(`levelOneError.error_six`),
        function (value) {
          const { sentences } = this.parent;
          // Check if "Andere Gründe" is selected and if the 'otherReason' is provided
          if (sentences.includes('Other') && (!value || value.trim() === '')) {
            return false; // Fail validation if no value is provided
          }
          return true; // Pass validation if otherReason is either not required or is provided
        },
      ),
    });
  } else if (renderForm === 7) {
    return Yup.object().shape({
      sourcesOfStrength: Yup.array()
        .min(1, t(`levelOneError.error_five`)) // Validate at least one checkbox
        .test(
          'include-other-reason',
          t(`levelOneError.error_six`),
          function (sourcesOfStrength) {
            const { otherReason } = this.parent;
            if (sourcesOfStrength.includes('Other')) {
              return otherReason && otherReason.trim() !== ''; // Validate otherReason if 'andere Gründe:' is selected
            }
            return true;
          },
        ),
      otherSourcesOfStrength: Yup.string().test(
        'is-required-if-other',
        t(`levelOneError.error_six`),
        function (value) {
          const { sourcesOfStrength } = this.parent;
          return (
            !sourcesOfStrength.includes('Other') ||
            (value && value.trim() !== '')
          );
        },
      ),
    });
  }

  // Add other cases as needed
  return Yup.object(); // Default empty schema
};

