import avatar1 from "../assets/avatar1.png"
import avatar2 from "../assets/avatar2.png"
import avatar3 from "../assets/avatar3.png"
import avatar4 from "../assets/avatar4.png"
import avatar5 from "../assets/avatar5.png"
import avatar6 from "../assets/avatar6.png"


// export const MultiOptions = [
//     { title: 'Depression', value: 'Depression' },
//     { title: 'Manie', value: 'Mania' },
//     { title: 'Bipolare Störung', value: 'Bipolar disorder' },
//     { title: 'Persönlichkeitsstörung', value: 'Personality disorder' },
//     { title: 'Angststörung', value: 'Anxiety disorder' },
//     { title: 'Essstörung', value: 'Eating disorder' },
//     { title: 'Psychose', value: 'Psychosis' },
//     { title: 'Suchterkrankung', value: 'Addiction disorder' },
//     { title: 'AD(H)S', value: 'AD(H)S' },
//     { title: 'Autismus', value: 'Autism' },
//     { title: 'Zwangstörung', value: 'Obsessive compulsive disorder' },
//     { title: 'Traumafolgestörung', value: 'Trauma sequelae' },
//     { title: 'Andere', value: 'Other' },
// ];

// export const LevelTwoRed = [
//     'Ich bin eigentlich jemand, der kämpft.',
//     'Ich glaube, dass ich lernen kann, mit meinen Problemen umzugehen.',
//     'Ich glaube, dass ich für meine Probleme passende Lösungen finden kann, wenn ich sie suche.',
//     'Es gibt für alles mindestens eine Lösung.',
//     'Auf und Abs gehören zum Leben.',
//     'Auch Stresssituationen gehen vorüber!',
//     'Andere Gründe:',
// ];

// export const StrengthData = [
//     'Fotos von Freunden/Angehörigen',
//     'Fotos vom Urlaub, Geburtstagsparty etc.',
//     'Fotos vom Haustier',
//     'Gebete/Zitate',
//     'Lieblings CD, Video',
//     'Lieblingsbuch/Lieblingsroman',
//     'Selbst gemalte Bilder',
//     'Kopien von guten Zeugnissen',
//     'Pokale oder Auszeichnungen',
//     'andere Gründe',
// ];



export const MultiOptions = [
    { title: 'Depression', value: 'Depression', keyName: 'depression' },
    { title: 'Mania', value: 'Mania', keyName: 'mania' },
    { title: 'BipolarDisorder', value: 'Bipolar disorder', keyName: 'bipolarDisorder' },
    { title: 'PersonalityDisorder', value: 'Personality disorder', keyName: 'personalityDisorder' },
    { title: 'AnxietyDisorder', value: 'Anxiety disorder', keyName: 'anxietyDisorder' },
    { title: 'EatingDisorder', value: 'Eating disorder', keyName: 'eatingDisorder' },
    { title: 'Psychosis', value: 'Psychosis', keyName: 'psychosis' },
    { title: 'AddictionDisorder', value: 'Addiction disorder', keyName: 'addictionDisorder' },
    { title: 'ADHS', value: 'AD(H)S', keyName: 'adhs' },
    { title: 'Autism', value: 'Autism', keyName: 'autism' },
    { title: 'OCD', value: 'Obsessive compulsive disorder', keyName: 'ocd' },
    { title: 'TraumaSequelae', value: 'Trauma sequelae', keyName: 'traumaSequelae' },
    { title: 'Other', value: 'Other', keyName: 'other' },
];

export const LevelTwoRed = [
    { text: 'Fighter', keyName: 'fighter' },
    { text: 'LearnToCope', keyName: 'learnToCope' },
    { text: 'FindSolutions', keyName: 'findSolutions' },
    { text: 'SolutionExists', keyName: 'solutionExists' },
    { text: 'UpsAndDowns', keyName: 'upsAndDowns' },
    { text: 'StressPasses', keyName: 'stressPasses' },
    { text: 'OtherReasons', keyName: 'otherReasons' },
];

export const StrengthData = [
    { text: 'PhotosFriends', keyName: 'photosFriends' },
    { text: 'PhotosVacations', keyName: 'photosVacations' },
    { text: 'PetPhotos', keyName: 'petPhotos' },
    { text: 'PrayersQuotes', keyName: 'prayersQuotes' },
    { text: 'FavoriteMedia', keyName: 'favoriteMedia' },
    { text: 'FavoriteBook', keyName: 'favoriteBook' },
    { text: 'Paintings', keyName: 'paintings' },
    { text: 'GoodCertificates', keyName: 'goodCertificates' },
    { text: 'Awards', keyName: 'awards' },
    { text: 'OtherReasons', keyName: 'otherReasons' },
];
export const avatars = [
    { id: 1, src: avatar1, value: 'avatar1' },
    { id: 2, src: avatar2, value: 'avatar2' },
    { id: 3, src: avatar3, value: 'avatar3' },
    { id: 4, src: avatar4, value: 'avatar4' },
    { id: 5, src: avatar5, value: 'avatar5' },
    { id: 6, src: avatar6, value: 'avatar6' },
];
