import React from 'react'
import mainBG from '../../../assets/EinführungMainBG.png'
import ellipseBG from '../../../assets/EllipseBG.png'
import { useTranslation } from "react-i18next";
const JaAccept = ({ nextClick, backClick, formik }) => {
    const { t } = useTranslation()

    return (
        <>
            <div style={{ background: `url(${mainBG})` }} className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'>
                <div></div>
                <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                    <div className='relative pt-24 w-full max-w-[36rem] mx-auto flex flex-col justify-between h-full justify-self-center text-center pb-4'>
                        <div>
                            <h1 className='font-bold text-white text-xl'>{t(`s-12-title`)}</h1>
                            <p className='font-normal italic text-white text-base mt-12'>{t(`s-12-desc`)}   <a target='_blank' className='underline' href={t(`drive_link_4`)}>{t(`s-accept-link`)}</a> {t(`s-accept-des`)}</p>
                            <div className=" max-w-[31rem] mx-auto">
                                <div className="flex items-start gap-2 py-2">
                                    <input
                                        type="checkbox"
                                        id="policy"
                                        name="termsAndCondition"
                                        checked={formik.values.termsAndCondition}
                                        onChange={formik.handleChange}
                                        className="radio-circle !w-full !max-w-[20px]"
                                    />
                                    <label

                                        className="font-semibold text-base text-white text-left"
                                    >
                                        {t(`s-12-check`)}
                                    </label>
                                </div>

                                {formik.errors.termsAndCondition && (
                                    <div className="text-red-500 text-sm text-left">{formik.errors.termsAndCondition}</div>
                                )}
                            </div>

                        </div>

                        <div className='pt-6 flex gap-6'>
                            <button className='w-full button_primary' onClick={backClick} >{t(`s-2-btn1`)}</button>
                            <button className='w-full button_secodry' onClick={nextClick} >{t(`s-2-btn2`)}</button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default JaAccept