import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LevelThree from '../screens/LevelThree';
import Starting from '../screens/starting';
import LevelOne from '../screens/levelone';
import LevelTwo from '../screens/LevelTwo';
import LevelFour from '../screens/LevelFour';
import LevelFive from '../screens/LevelFive';
import LevelSix from '../screens/LevelSix';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../redux/language/languageSlice';
import i18n from '../i18n';
import Login from '../screens/login';
import Dashboard from '../screens/dashboard';
import PrivateRoute from '../screens/common/constant/privateRoute';

const MainStack = () => {
    const dispatch = useDispatch();
    const currentLanguage = useSelector((state) => state.language.language);

    // // Detect browser language
    // useEffect(() => {
    //     const browserLang = navigator.language.slice(0, 2); // 'de', 'tr', or 'hr'
    //     const supportedLanguages = ['de', 'tr', 'hr'];

    //     const langToSet = supportedLanguages.includes(browserLang) ? browserLang : 'de';
    //     dispatch(setLanguage(langToSet));
    //     i18n.changeLanguage(langToSet);
    // }, [dispatch]);

    // // Change i18n when language state changes
    useEffect(() => {
        i18n.changeLanguage(currentLanguage);
    }, [currentLanguage]);
    return (
        <Router>
            <React.Suspense fallback={''}>
                <Routes>
                    <Route path="/" exact element={<Starting />} />
                    <Route path="/level-one" exact element={<LevelOne />} />
                    <Route path="/level-two" element={<LevelTwo />} />
                    <Route path="/level-three" exact element={<LevelThree />} />
                    <Route path="/level-four" exact element={<LevelFour />} />
                    <Route path="/level-five" exact element={<LevelFive />} />
                    <Route path="/level-six" exact element={<LevelSix />} />
                    <Route path="/login" exact element={<Login />} />
                    <Route
                        path="/dashboard"
                        element={
                            <PrivateRoute>
                                <Dashboard />
                            </PrivateRoute>
                        }
                    />
                </Routes>
            </React.Suspense>
        </Router>
    );
};

export default MainStack;
