import React, { useEffect, useRef, useState } from 'react';
import ellipseBG from '../../assets/EllipseBG.png'
import BatteryPer from '../../assets/batterypercentage.png'
import Battery from '../../assets/battery.png'
import Tree from '../../assets/tree.png'
import TreeTypes from '../../assets/treeTypes.png'
import Intial from './forms/intial'
import { useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getValidationSchema } from './constant';
import { useSelector, useDispatch } from 'react-redux';
import ScalarForm, { sliderConfig } from '../common/forms/ScalarForm';
import LevelRadioSelectionlastSeven from '../common/forms/LevelRadioSelectionlastSeven';
import { firstSevenQuestions, getInitialValues, lastSevenQuestions } from '../common/constant/data';
import LevelRadioSelection from '../common/forms/LevelRadioSelection';
import techVideo from '../../assets/tech.mov'
import MoodSelector from './forms/MoodSelector';
import EmojiDragDrop from './forms/EmojiDragDrop';
import { setUserLevelFiveInfo } from '../../redux/Started';
import TreeWithBranches from './forms/TreeWithBranches';
import { handleAddedPersonalInfoAction } from '../../redux/Started/apisActions';
import Spiner from '../common/constant/spiner';
import { useTranslation } from "react-i18next";
import OverviewMail from '../common/forms/overviewMail';
import ModuleNav from '../common/forms/moduleNav';
const LevelFive = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams();
    const extractInitialValue = () => {
        const renderValue = searchParams.get("render");
        return renderValue ? parseInt(renderValue, 10) : 0;
    };

    const [renderForm, setRenderForm] = useState(extractInitialValue);
    const [validationSchema, setValidationSchema] = useState(
        getValidationSchema(renderForm, t),
    );

    useEffect(() => {
        setValidationSchema(getValidationSchema(renderForm - 1, t)); // Update schema when renderForm changes
    }, [renderForm]);

    const { levelFiveForm, loading } = useSelector(state => state.initialUserInfo);
    const { userID } = useSelector(state => state.user);
    const levelFiveInitialValues = {
        ...sliderConfig.reduce(
            (values, slider) => ({
                ...values,
                [slider.name]: 0,
            }),
            {},
        ),
        ...firstSevenQuestions.reduce(
            (values, question) => ({
                ...values,
                [question.keyName]: '', // Default value for each question
            }),
            {},
        ),
        ...lastSevenQuestions.reduce(
            (values, question) => ({
                ...values,
                [question.keyName]: '', // Default value for each question
            }),
            {},
        ),
        strengths: [
            { branch: 'Zweig1', value: '' },
            { branch: 'Zweig2', value: '' },
            { branch: 'Zweig3', value: '' },
            { branch: 'Zweig4', value: '' },
            { branch: 'Zweig5', value: '' },
        ],
        moodCard: '',
        drawing: null,
        expressFeelings: ['', '', ''],
        loves: ['Animals'],
        Other: 'Hello',
    }
    const formik = useFormik({
        initialValues: getInitialValues(levelFiveInitialValues, levelFiveForm),
        validationSchema,
        onSubmit: values => {
            const data = {
                userId: userID || '',
                LevelFive: JSON.stringify(values),
            };
            if (renderForm === 10) {
                dispatch(setUserLevelFiveInfo(values));
                console.log({ data });
                dispatch(
                    handleAddedPersonalInfoAction({
                        data,
                        callback: res => setRenderForm(renderForm + 1),
                    }),
                );
                // navigate('/level-six')
            } else {
                if (renderForm == 13) {
                    navigate('/level-six')
                }
                formik.setTouched({});
                setRenderForm(renderForm + 1);
            }
            console.log('Form Values:', values);
        },
    });

    console.log("content in render form", renderForm)

    return (
        <>
            <div className='self-center pt-4 text-center '>
                {renderForm === 2 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >
                            {t(`l1-2-title`)}
                        </h1>
                        <h1 className='text-[14px] text-mainBlue font-bold italic ' >
                            {t(`l1-2-title-one`)}

                        </h1>
                        <h1 className='text-[14px] text-mainBlue font-semibold' >    {t(`l1-2-title-two`)}</h1>
                    </>
                )}

                {renderForm === 0 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >Level 5
                        </h1>
                        <h1 className='text-[16px] text-mainBlue font-bold italic ' >
                            {t(`l5-0-title`)} </h1>
                        <h1 className='text-[16px] text-mainBlue font-semibold' >{t(`l5-0-title_two`)}</h1>
                    </>
                )}
                {renderForm === 4 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l5-4-title`)}  </h1>
                    </>
                )}
                {renderForm === 5 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l5-5-title`)} </h1>
                    </>
                )}
                {renderForm === 6 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l5-6-title`)}  </h1>
                    </>
                )}
                {renderForm === 7 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l5-7-title`)}   </h1>
                    </>
                )}
                {renderForm === 8 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l5-8-title`)}    </h1>
                    </>
                )}
                {renderForm === 9 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l5-9-title`)}    </h1>
                    </>
                )}
                {renderForm === 10 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l5-10-title`)}    </h1>
                    </>
                )}
                {renderForm === 11 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >{t(`overview_title_super`)} <br /> {t(`introduction_l5_title`)}   </h1>
                    </>
                )}
                {renderForm === 12 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`overview_title`)}   </h1>
                    </>
                )}
                {renderForm === 13 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`overview_title`)}  <br /> Level 5  </h1>
                    </>
                )}

            </div>
            <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                <div className='relative pt-20 w-full max-w-[36rem] mx-auto flex flex-col justify-between h-full justify-self-center text-center py-2'>
                    {/* <Intial /> */}
                    <div className="max-w-[32rem] mx-auto">
                        {renderForm === 0 && <Intial />}
                        {renderForm === 1 && <ScalarForm formik={formik} />}
                    </div>


                    {renderForm === 2 && <LevelRadioSelection formik={formik} />}
                    {renderForm === 3 && <LevelRadioSelectionlastSeven formik={formik} />}

                    {renderForm === 4 && (
                        <div className="flex flex-col gap-5 items-start">
                            <p className="text-[13px] text-white  font-[500]">
                                {t(`l5-4-desc`)}
                            </p>
                            <p className="text-[13px] text-white italic font-[400]">
                                {t(`l5-4-para`)}
                            </p>
                            <div className="relative max-w-[360px] mx-auto px-4 ">
                                <img
                                    src={BatteryPer}
                                    alt="Battery Percentage"
                                    className="w-full"
                                />
                                <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    step="1"
                                    value={formik.values.battery}
                                    onChange={(e) => formik.setFieldValue("battery", Number(e.target.value))}
                                    onBlur={() => formik.setFieldTouched("battery", true)}
                                    className="absolute z-10 !w-[85%] left-[1%] !top-[10%] translate-x-[-50%] !translate-y-[-50%] bg-transparent appearance-none custom-range-slider"
                                />
                                <img
                                    src={Battery}
                                    alt="Battery Percentage"
                                    className="w-full"
                                />
                            </div>
                        </div>
                    )}

                    {/* Form 4 and 5: Lebensbaum */}
                    {(renderForm === 5) && (
                        <div className="flex flex-col gap-5 items-center">
                            <p className="text-[15px] text-white  font-[500]">
                                {t(`l5-5-desc`)}
                            </p>
                            <TreeWithBranches formik={formik} />
                            {/* <img
                                src={Tree}
                                alt="Tree"
                                className="w-[185px] mx-auto object-contain"
                            /> */}
                        </div>
                    )}

                    {/* Form 6: Tannenbaum der Gefühle */}
                    {renderForm === 7 && (
                        <div className="flex flex-col gap-5 items-center">
                            <p className="text-lg font-medium font-poppins text-white">
                                {t(`l5-7-desc`)}
                            </p>
                            <p className="text-base font-regular font-poppins text-white">
                                {t(`l5-7-para`)}
                            </p>
                            <div className="grid grid-cols-2 gap-5 max-w-[300px]">
                                <div className="relative">
                                    <img

                                        src={TreeTypes}
                                        alt="Tree Types"
                                        className="w-full object-contain"
                                    />

                                    <a href={t(`drive_link_17`)} target='_blank' className='underline absolute right-0 top-2 text-[10px]' > {t('l5-7-treeone')}</a>
                                    <a href={t(`drive_link_18`)} target='_blank' className='underline absolute right-0 top-[65%] text-[10px]' > {t('l5-7-treetwo')}</a>
                                    <a href={t(`drive_link_19`)} target='_blank' className='underline absolute right-0 top-[85%] text-white text-[10px]' > {t('l5-7-treethree')} </a>
                                </div>

                                <div className="flex flex-col gap-3 w-full max-w-md">
                                    {formik.values.expressFeelings.map((value, index) => (
                                        <div key={index} className="mb-1">
                                            {/* Input field */}
                                            <textarea
                                                className={`w-full h-[64px] p-2 border rounded ${formik.touched.expressFeelings?.[index] &&
                                                    formik.errors.expressFeelings?.[index]
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                                    }`}
                                                value={value}
                                                // placeholder="Gefühle eingeben"
                                                onChange={(e) =>
                                                    formik.setFieldValue(`expressFeelings[${index}]`, e.target.value)
                                                }
                                                onBlur={() =>
                                                    formik.setFieldTouched(`expressFeelings[${index}]`, true)
                                                }
                                            ></textarea>

                                            {/* Error Message */}
                                            {formik.touched.expressFeelings?.[index] &&
                                                formik.errors.expressFeelings?.[index] && (
                                                    <p className="text-red-500 text-[10px] text-left mt-1">
                                                        {formik.errors.expressFeelings[index]}
                                                    </p>
                                                )}
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    )}
                    {renderForm === 6 && (
                        <MoodSelector formik={formik} />
                    )}
                    {renderForm === 8 && (
                        <EmojiDragDrop formik={formik} />
                    )}
                    {renderForm === 9 && (
                        <div className="flex flex-col gap-3">
                            <p className="text-[14px] text-white mb-2 font-[500]">
                                {t(`l5-9-desc1`)}
                            </p>
                            <p className="text-[14px] text-white mb-2 font-[500]">
                                {t(`l5-9-desc2`)}
                            </p>
                            <p className="text-[14px] text-white mb-2 font-[500]">
                                {t(`l5-9-para1`)} <br />
                                {t(`l5-9-para1_one`)} <br />
                                {t(`l5-9-para2`)}
                            </p>
                            <a
                                target='_blank'
                                className="text-[14px] text-white italic font-bold underline cursor-pointer"
                                href={t(`drive_link_20`)}
                            >
                                {t(`l5-9-para3`)}
                            </a>
                        </div>
                    )}
                    {renderForm === 10 && (
                        <div className="flex flex-col gap-3">
                            <p className="text-[14px] text-white mb-2 font-[500]">
                                {t(`l5-10-desc`)}
                            </p>

                            <div className='mt-2 max-w-[270px] mx-auto'>
                                <video
                                    className='w-full h-auto '
                                    controls
                                >
                                    <source src={t(`video_link_15`)} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className="text-center">
                                <p className="text-[13px] text-white italic font-bold">{t(`l5-10-para1`)}</p>
                                <p className="text-[13px] text-white italic font-bold">
                                    {t(`l5-10-para2`)}
                                </p>
                                <p className="text-[13px] text-white italic font-bold">
                                    {t(`l5-10-para3`)}
                                </p>
                            </div>
                        </div>
                    )}

                    {renderForm === 11 && <OverviewMail />}
                    {renderForm === 12 && <ModuleNav />}
                    {renderForm === 13 && <div className='text-left' >

                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`}  >
                            {t(`overview_module_start_five`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_1_five`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_2_five`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_3_five`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_4_five`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_5_five`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_6_five`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_7_five`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_8_five`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_9_five`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_10_five`)}
                        </p>
                    </div>}

                    <div className='pt-4 flex gap-2 mb-2'>
                        <button className='w-full button_primary' onClick={() => {
                            if (renderForm === 0) {
                                navigate('/level-four?render=15');
                            } else {
                                setRenderForm(renderForm - 1);
                            }
                            // navigation.goBack();
                        }} >{t(`s-2-btn1`)}</button>
                        <button className='w-full button_secodry relative' onClick={() => {
                            formik.handleSubmit();
                        }}
                            disabled={loading}
                        >

                            {loading && (
                                <Spiner />
                            )}
                            <span className={loading ? "opacity-0" : ""}>{t(`s-2-btn2`)}</span>

                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LevelFive
