import React, { useState, useEffect } from 'react'
import Table from './Table'
import { Pagination } from './Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../redux/auth/loginSlice'
import { get } from '../../utils/axios'
import { ThreeCircles } from 'react-loader-spinner'

const Dashboard = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { token } = useSelector((state) => state.login); // Get token from Redux
    const [dashboardData, setDashboardData] = useState(null);
    const [users, setUsers] = useState([]);
    const [paginationData, setPaginationData] = useState({})
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1)
    const headers = [
        // { label: 'ID', key: 'id' },
        { label: 'Email', key: 'email' },
        { label: 'Age', key: 'age' },
        { label: 'Genter', key: 'gender' },
        { label: 'Federal State', key: 'federalState' },
        { label: 'Role', key: 'role' },
    ];
    useEffect(() => {
        const fetchDashboardData = async () => {
            setLoading(true)
            try {
                if (!token) {
                    throw new Error('No token found! Please login.');
                }
                const response = await get(`/user?page=${page}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setDashboardData(response?.data);
                setUsers(response?.data?.users)
                setPaginationData(response?.data?.pagination)
                setLoading(false)
            } catch (err) {
                setError(err.response?.data?.message || err.message);
            }
        };

        fetchDashboardData();
    }, [page]);

    const getAllCsv = async () => {
        try {
            if (!token) {
                throw new Error('No token found! Please login.');
            }
            const response = await get(`/user/download`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } catch (err) {
            setError(err.response?.data?.message || err.message);
        }
    };

    const logoutFunction = () => {
        dispatch(logout(null));
        navigate('/login')
        window.location.reload()
    }



    console.log("data in dashboard", dashboardData)

    return (
        <div className='py-4'>
            <div className="max-w-[1340px] mx-auto p-4 bg-[#111D4EDB] rounded-md min-h-[95vh]">
                <div className="flex justify-between items-center">
                    <h1 className="text-2xl font-semibold mb-4 text-white">Simurg Users</h1>
                    <button className='button_primary' onClick={logoutFunction} >Logout</button>
                </div>
                <div className="min-h-[60vh] w-full relative">
                    {loading ? <>
                        <div className="absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%]">
                            <ThreeCircles
                                visible={true}
                                height="100"
                                width="100"
                                color="#f9dcd4"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div>
                    </> : <>
                        <Table headers={headers} data={users} page={page} getAllCsv={getAllCsv} />
                    </>}

                </div>

                <div className="my-5">
                    <Pagination currentPage={page} totalPages={paginationData?.totalPages} onPageChange={setPage} />
                </div>
            </div>
        </div>
    )
}

export default Dashboard
