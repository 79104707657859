import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    language: 'de', // Default German
};

const LanguageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
    },
});

export const { setLanguage } = LanguageSlice.actions;
export default LanguageSlice.reducer;
