import React, { useEffect, useRef, useState } from 'react';
import ellipseBG from '../../assets/EllipseBG.png'
import Intial from './forms/intial'
import { useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getValidationSchema } from './constant';
import { useSelector, useDispatch } from 'react-redux';
import ScalarForm, { sliderConfig } from '../common/forms/ScalarForm';
import LevelRadioSelectionlastSeven from '../common/forms/LevelRadioSelectionlastSeven';
import { firstSevenQuestions, getInitialValues, lastSevenQuestions } from '../common/constant/data';
import LevelRadioSelection from '../common/forms/LevelRadioSelection';
import techVideo from '../../assets/tech.mov'
import { setUserLevelThreeInfo } from '../../redux/Started';
import { handleAddedPersonalInfoAction } from '../../redux/Started/apisActions';
import Spiner from '../common/constant/spiner';
import { useTranslation } from "react-i18next";
import OverviewMail from '../common/forms/overviewMail';
import ModuleNav from '../common/forms/moduleNav';


const LevelThree = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const extractInitialValue = () => {
        const renderValue = searchParams.get("render");
        return renderValue ? parseInt(renderValue, 10) : 0;
    };

    const [renderForm, setRenderForm] = useState(extractInitialValue);
    const [validationSchema, setValidationSchema] = useState(
        getValidationSchema(renderForm, t),
    );

    useEffect(() => {
        setValidationSchema(getValidationSchema(renderForm - 1, t)); // Update schema when renderForm changes
    }, [renderForm]);

    const { levelThreeForm, loading } = useSelector(state => state.initialUserInfo);
    const { userID } = useSelector(state => state.user);
    const levelThreeInitialValues = {
        ...sliderConfig.reduce(
            (values, slider) => ({
                ...values,
                [slider.name]: 0,
            }),
            {},
        ),
        ...firstSevenQuestions.reduce(
            (values, question) => ({
                ...values,
                [question.keyName]: '', // Default value for each question
            }),
            {},
        ),
        ...lastSevenQuestions.reduce(
            (values, question) => ({
                ...values,
                [question.keyName]: '', // Default value for each question
            }),
            {},
        ),
    }
    const formik = useFormik({
        initialValues: getInitialValues(levelThreeInitialValues, levelThreeForm),
        validationSchema,
        onSubmit: values => {
            const data = {
                userId: userID || '',
                LevelThree: JSON.stringify(values),
            };
            if (renderForm === 8) {
                dispatch(setUserLevelThreeInfo(values));
                dispatch(
                    handleAddedPersonalInfoAction({
                        data,
                        callback: res => setRenderForm(renderForm + 1),
                    }),
                );
                // navigate('/level-four');
            } else {
                if (renderForm == 11) {
                    navigate('/level-four')
                }
                formik.setTouched({});
                setRenderForm(renderForm + 1);
            }
            console.log('Form Values:', values);
        },
    });


    return (
        <>
            <div className='self-center pt-4 text-center '>
                {renderForm === 2 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >
                            {t(`l1-2-title`)}
                        </h1>
                        <h1 className='text-[14px] text-mainBlue font-bold italic ' >
                            {t(`l1-2-title-one`)}

                        </h1>
                        <h1 className='text-[14px] text-mainBlue font-semibold' >    {t(`l1-2-title-two`)}</h1>
                    </>
                )}
                {renderForm === 0 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >Level 3</h1>
                        <h1 className='text-[16px] text-mainBlue font-bold italic ' >
                            {t(`l3-0-title`)} </h1>
                        <h1 className='text-[16px] text-mainBlue font-semibold' >{t(`l3-0-title_two`)}</h1>
                    </>
                )}
                {renderForm === 4 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l3-4-title`)} </h1>
                    </>
                )}
                {renderForm === 5 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l3-5-title`)} <br /> {t(`l3-5-title_two`)} </h1>
                    </>
                )}
                {renderForm === 6 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l3-6-title`)}  </h1>
                    </>
                )}
                {renderForm === 7 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l3-7-title`)}   </h1>
                    </>
                )}
                {renderForm === 8 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l3-8-title`)}   </h1>
                    </>
                )}
                {renderForm === 9 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >{t(`overview_title_super`)} <br /> {t(`introduction_l3_title`)}   </h1>
                    </>
                )}
                {renderForm === 10 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`overview_title`)}   </h1>
                    </>
                )}
                {renderForm === 11 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`overview_title`)}  <br /> Level 3  </h1>
                    </>
                )}

            </div>
            <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                <div className='relative pt-20 w-full max-w-[36rem] mx-auto flex flex-col justify-between h-full justify-self-center text-center py-2'>
                    {/* <Intial /> */}
                    <div className="max-w-[32rem] mx-auto">
                        {renderForm === 0 && <Intial />}
                        {renderForm === 1 && <ScalarForm formik={formik} />}
                    </div>


                    {renderForm === 2 && <LevelRadioSelection formik={formik} />}
                    {renderForm === 3 && <LevelRadioSelectionlastSeven formik={formik} />}

                    {renderForm === 4 && (
                        <div className="flex flex-col gap-5 justify-start items-start">
                            <p className="text-[14px] text-white italic font-medium">
                                {t(`l3-4-desc`)}
                            </p>
                            <div className='mt-2 max-w-[270px] mx-auto'>
                                <video
                                    className='w-full h-auto '

                                    controls
                                >
                                    <source src={t(`video_link_5`)} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    )}

                    {renderForm === 5 && (
                        <div className="flex flex-col gap-5 justify-start items-start">
                            <p className="text-[14px] text-white italic font-bold">
                                {t(`l3-5-heading`)}
                            </p>
                            <p className="text-[14px] text-white italic font-medium">
                                {t(`l3-5-desc`)}
                            </p>
                            <div className='mt-2 max-w-[270px] mx-auto'>
                                <video
                                    className='w-full h-auto '

                                    controls
                                >
                                    <source src={t(`video_link_6`)} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    )}

                    {renderForm === 6 && (
                        <div className="flex flex-col gap-1 justify-start items-start">
                            <p className="text-[14px] text-white italic font-medium">
                                {t(`l3-6-desc`)}
                            </p>
                            <div className='mt-2 max-w-[270px] mx-auto'>
                                <video
                                    className='w-full h-auto '

                                    controls
                                >
                                    <source src={t(`video_link_7`)} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    )}

                    {renderForm === 7 && (
                        <div className="flex flex-col gap-0 justify-start items-center">
                            <p className="text-[13px] text-white italic font-bold">
                                {t(`l3-7-desc`)}
                            </p>

                            <div className='mt-2 max-w-[270px] mx-auto'>
                                <video
                                    className='w-full h-auto '

                                    controls
                                >
                                    <source src={t(`video_link_8`)} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    )}

                    {renderForm === 8 && (
                        <div className="flex flex-col gap-2">
                            <p className="text-[12px] text-white italic font-bold">
                                {t(`l3-8-desc`)}
                            </p>
                            <div className='mt-2 max-w-[270px] mx-auto'>
                                <video
                                    className='w-full h-auto '

                                    controls
                                >
                                    <source src={t(`video_link_9`)} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className="text-center">
                                <p className="text-[14px] text-white italic font-bold">{t(`l3-8-para`)}</p>
                                <p className="text-[14px] text-white italic font-bold">{t(`l3-8-para_one`)}</p>
                                <p className="text-[14px] text-white italic font-bold">{t(`l3-8-para_two`)}</p>
                            </div>
                        </div>
                    )}

                    {renderForm === 9 && <OverviewMail />}
                    {renderForm === 10 && <ModuleNav />}
                    {renderForm === 11 && <div className='text-left' >

                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`}  >
                            {t(`overview_module_start_three`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_1_three`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_2_three`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_3_three`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_4_three`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_5_three`)}
                        </p>
                        <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                            {t(`overview_module_6_three`)}
                        </p>
                    </div>}


                    <div className='pt-4 mb-2 flex gap-2'>
                        <button className='w-full button_primary' onClick={() => {
                            if (renderForm === 0) {
                                navigate('/level-two?render=11');
                            } else {
                                setRenderForm(renderForm - 1);
                            }
                            // navigation.goBack();
                        }} >{t(`s-2-btn1`)}</button>
                        <button className='w-full button_secodry relative' onClick={() => {
                            formik.handleSubmit();
                        }}
                            disabled={loading}
                        >

                            {loading && (
                                <Spiner />
                            )}
                            <span className={loading ? "opacity-0" : ""}>{t(`s-2-btn2`)}</span>

                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LevelThree
