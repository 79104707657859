// src/features/auth/LoginAuthSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuthenticated: false,
    user: null,
    token: null
};

const LoginAuthSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        login: (state, action) => {
            state.isAuthenticated = true;
            state.loginUser = action.payload.user; // Store user details
            state.token = action.payload.token; // Store token
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.loginUser = null;
            state.token = null;
        },
    },
});

export const { login, logout } = LoginAuthSlice.actions;

export default LoginAuthSlice.reducer;
