import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


// Import language files
import de from './locales/de.json'; // German
import tr from './locales/tr.json'; // Turkish
import hr from './locales/hr.json'; // Croatian

const resources = {
    de: { translation: de },
    tr: { translation: tr },
    hr: { translation: hr },
};


i18n
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'de', // Default to German
        lng: 'de', // Initial language
        interpolation: { escapeValue: false },
    });

export default i18n;
