import React from 'react'
import mainBG from '../../assets/EinführungMainBG.png'
import ellipseBG from '../../assets/EllipseBG.png'
import { useTranslation } from "react-i18next";
import OverviewMail from '../common/forms/overviewMail'
const OverViewStart = ({ nextClick, backClick }) => {

    const { t } = useTranslation()
    const isSmallScreen = window.innerHeight <= 600;
    return (
        <>
            <div style={{ background: `url(${mainBG})` }} className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'>
                <div>
                    <div className='font-bold text-base text-[#0F32C0E5] text-center self-center italic py-8'> {t(`overview_title_super`)}!  <br /> {t(`introduction_title`)}</div>
                </div>
                <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                    <div className={`relative ${isSmallScreen ? 'pt-12' : 'pt-20'} mx-auto w-full ${isSmallScreen ? 'max-w-[38rem]' : 'max-w-[36rem]'} flex flex-col justify-between h-full justify-self-center text-center pb-4`}>
                        <div>
                            <OverviewMail />

                        </div>

                        <div className={`${isSmallScreen ? 'pt-2' : 'pt-10'} flex gap-6`}>
                            <button className='w-full button_primary' onClick={backClick} >{t(`s-2-btn1`)}</button>
                            <button className='w-full button_secodry' onClick={nextClick} >{t(`s-2-btn2`)}</button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default OverViewStart