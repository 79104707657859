import React, { useEffect, useRef, useState } from 'react';
import ellipseBG from '../../assets/EllipseBG.png'
import { useFormik } from 'formik';
import BalloonScreen from './BalloonScreen';
import VideoScreen from './VideoScreen';
import { useDispatch, useSelector } from 'react-redux';
import Intial from './forms/intial';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getValidationSchema, transformData } from './constant/data';
import ScalarForm, { sliderConfig } from '../common/forms/ScalarForm';
import LevelRadioSelection from '../common/forms/LevelRadioSelection';
import LevelRadioSelectionlastSeven from '../common/forms/LevelRadioSelectionlastSeven';
import techVideo from '../../assets/tech.mov'
import { levelOneMcqs } from './constants/data';
import { firstFiveInputQuestions, firstSevenQuestions, lastFiveInputQuestions, lastSevenQuestions, DATA_Playlist, getInitialValues } from '../common/constant/data';
import { handleAddedPersonalInfoAction } from '../../redux/Started/apisActions';
import { setUserLevelOneInfo } from '../../redux/Started';
import ProfileFormOne from '../common/forms/ProfileFormOne';
import ProfileFormSecondhalf from '../common/forms/ProfileFormSecondhalf';
import StressForm from './forms/StressForm';
import PlayList from './forms/PlayList';
import Spiner from '../common/constant/spiner';
import { useTranslation } from "react-i18next";
import OverviewMail from '../common/forms/overviewMail';
import ModuleNav from '../common/forms/moduleNav';

const LevelOne = () => {
    const navigate = useNavigate();
    const { t } = useTranslation()
    const { levelOneForm, loading } = useSelector(state => state.initialUserInfo);
    const { userID } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const extractInitialValue = () => {
        const renderValue = searchParams.get("render");
        return renderValue ? parseInt(renderValue, 10) : 0;
    };

    const [renderForm, setRenderForm] = useState(extractInitialValue);
    const [validationSchema, setValidationSchema] = useState(
        getValidationSchema(renderForm, t),
    );

    console.log("data in levelform", levelOneForm)

    useEffect(() => {
        setValidationSchema(getValidationSchema(renderForm - 1, t)); // Update schema when renderForm changes
    }, [renderForm, t]);
    const levelOneInitialValues = {
        ...sliderConfig.reduce(
            (values, slider) => ({
                ...values,
                [slider.name]: 0,
            }),
            {},
        ),
        ...firstSevenQuestions.reduce(
            (values, question) => ({
                ...values,
                [question.keyName]: '', // Default value for each question
            }),
            {},
        ),
        ...lastSevenQuestions.reduce(
            (values, question) => ({
                ...values,
                [question.keyName]: '', // Default value for each question
            }),
            {},
        ),
        ...firstFiveInputQuestions.reduce(
            (values, question) => ({
                ...values,
                [question.keyName]: '', // Initial empty value for each question
            }),
            {},
        ),
        ...lastFiveInputQuestions.reduce(
            (values, question) => ({
                ...values,
                [question.keyName]: '', // Initial empty value for each question
            }),
            {},
        ),
        answers: levelOneMcqs.map(() => ({
            checked: false, // Initially, none of the checkboxes are checked
            details: '', // Initially, the input is an empty string
        })),
        ...DATA_Playlist[0].data.reduce((values, item) => {
            values[item.keyName] = ''; // Initialize all fields with empty strings
            return values;
        }, {}),
        balloonsText: [],
    }

    const formik = useFormik({
        initialValues: getInitialValues(levelOneInitialValues, levelOneForm),
        validationSchema,
        onSubmit: values => {
            const apiPayload = transformData(values);
            console.log(apiPayload);

            const data = {
                userId: userID,
                LevelOne: JSON.stringify(apiPayload),
            };
            if (renderForm === 9) {
                console.log({ data });
                dispatch(setUserLevelOneInfo(values));
                dispatch(
                    handleAddedPersonalInfoAction({
                        data,
                        callback: res => {
                            setRenderForm(renderForm + 1)
                        },
                    }),
                );


            } else {
                if (renderForm === 8) {
                    // console.log({dataFormat});
                    dispatch(setUserLevelOneInfo(values));
                }
                if (renderForm === 12) {
                    navigate(`/level-two`)
                }
                // if (scrollViewRef.current) {
                //     scrollViewRef.current.scrollTo({ y: 0, animated: true });
                // }
                formik.setTouched({});
                setRenderForm(renderForm + 1);
            }
            // console.log('Form Values:', values);
        },
    });



    return (
        <>
            <div className='self-center pt-4 text-center '>
                {renderForm === 2 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >
                            {t(`l1-2-title`)}
                        </h1>
                        <h1 className='text-[14px] text-mainBlue font-bold italic ' >
                            {t(`l1-2-title-one`)}

                        </h1>
                        <h1 className='text-[14px] text-mainBlue font-semibold' >   {t(`l1-2-title-two`)}</h1>
                    </>
                )}
                {renderForm === 6 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >
                            {t('l1-6-title')}
                        </h1>

                        {/* <h1 className='text-[14px] text-mainBlue font-semibold' >   Im Leben können immer wieder Probleme auftreten. Es ist sehr wichtig, wie wir mit Problemen umgehen, ob wir über Probleme sprechen, oder uns (fachliche) Unterstützung holen.</h1>
                        <h1 className='text-[14px] text-mainBlue font-semibold' >   Bitte kreuze an, wer bzw. was Dir bisher geholfen hat, Deine Probleme zu bewältigen:</h1> */}
                    </>
                )}
                {renderForm === 0 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >{t(`l1-0-title_one`)}</h1>
                        <h1 className='text-[16px] text-mainBlue font-bold italic ' >
                            {t(`l1-0-title_two`)} </h1>
                        <h1 className='text-[16px] text-mainBlue font-semibold' >{t(`l1-0-title_three`)}</h1>
                    </>
                )}




                {renderForm === 8 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l1-9-title`)}  </h1>
                    </>
                )}
                {renderForm === 9 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`l1-10-title`)}     </h1>
                    </>
                )}
                {renderForm === 10 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`overview_title_super`)} <br /> {t(`introduction_l1_title`)}     </h1>
                    </>
                )}
                {renderForm === 11 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`overview_title`)}     </h1>
                    </>
                )}
                {renderForm === 12 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > {t(`overview_title`)} <br /> {t(`Level 1`)}     </h1>
                    </>
                )}

            </div>
            <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                <div className='relative pt-20 w-full max-w-[44rem] mx-auto flex flex-col justify-between h-full justify-self-center text-center py-2'>
                    {/* <Intial /> */}
                    <div className="max-w-[32rem] mx-auto">
                        {renderForm === 0 && <Intial />}
                        {renderForm === 1 && <ScalarForm formik={formik} />}
                    </div>

                    <div className="max-w-[36rem] mx-auto">
                        {renderForm === 2 && <LevelRadioSelection formik={formik} />}
                        {renderForm === 3 && <LevelRadioSelectionlastSeven formik={formik} />}
                        {renderForm === 6 && <StressForm formik={formik} />}
                        {renderForm === 7 && <PlayList formik={formik} />}
                        {renderForm === 8 && <BalloonScreen formik={formik} />}
                        {renderForm === 9 && <VideoScreen />}
                        {renderForm === 10 && <OverviewMail />}
                        {renderForm === 11 && <ModuleNav />}
                        {renderForm === 12 && <div className='text-left' >

                            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`}  >
                                {t(`overview_module_start_one`)}
                            </p>
                            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                                {t(`overview_module_1_one`)}
                            </p>
                            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                                {t(`overview_module_2_one`)}
                            </p>
                            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                                {t(`overview_module_3_one`)}
                            </p>
                            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                                {t(`overview_module_4_one`)}
                            </p>
                            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                                {t(`overview_module_5_one`)}
                            </p>
                            <p className={`text-[16px] font-bold text-white text-left hover:cursor-pointer`} >
                                {t(`overview_module_6_one`)}
                            </p>
                        </div>}

                    </div>

                    {renderForm === 4 && <ProfileFormOne formik={formik} />}
                    {renderForm === 5 && <ProfileFormSecondhalf formik={formik} />}





                    <div className="">
                        <div className='pt-4 mb-2 flex gap-2 max-w-[36rem] mx-auto'>
                            <button className='w-full button_primary' onClick={() => {
                                if (renderForm === 0) {
                                    navigate('/?render=14');
                                } else {
                                    setRenderForm(renderForm - 1);
                                }
                                // navigation.goBack();
                            }} >{t(`s-2-btn1`)}</button>
                            <button className='w-full button_secodry relative' onClick={() => {
                                formik.handleSubmit();
                            }}
                                disabled={loading}
                            >

                                {loading && (
                                    <Spiner />
                                )}
                                <span className={loading ? "opacity-0" : ""}>{t(`s-2-btn2`)}</span>

                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default LevelOne
