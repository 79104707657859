import { appImages } from '../../../assets';

export const exercise_list = [
  { titleKey: "yellIntoPillow", img: appImages.exeOne },
  { titleKey: "punchPillow", img: appImages.exeTwo },
  { titleKey: "stretchShake", img: appImages.exeThree },
  { titleKey: "hopOnLegs", img: appImages.exeFour },
  { titleKey: "tearNewspaper", img: appImages.exeFive },
];


export const exercise_reduce_list = [
  {
    titleKey: "handExercise",
    link: "drive_link_21",
    img: appImages.reduceOne,
  },
  {
    titleKey: "bellyExercise",
    link: "drive_link_22",
    img: appImages.reduceTwo,
  },
  {
    titleKey: "upperBodyExercise",
    link: "drive_link_23",
    img: appImages.reduceThree,
  },
  {
    titleKey: "armAndLegExercise",
    link: "drive_link_24",
    img: appImages.reduceFour,
  },
  {
    titleKey: "mirrorExercise",
    link: "drive_link_25",
    img: appImages.reduceFive,
  },
];


export const exercise_save_list = [
  { titleKey: "findOldJar" },
  { titleKey: "labelJar", img: appImages.saveTwo },
  { titleKey: "prepareNotes", img: appImages.saveThree },
  { titleKey: "writeDailyFeelings", img: appImages.saveFour },
];
